import React,{useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import globallink from "../Globalurl";
import axios from 'axios';
import { Markup } from 'interweave';
const Membership = ({ t, i18n }) => {
    const [fa_content,setfa_content]=useState('')
    const [en_content,seten_content]=useState('')
    let i18nextLng = localStorage.getItem('i18nextLng');
    const [lang,set_lang] = useState("py-5 gx-0 membership");

    
    useEffect(() => {
        window.scrollTo(0, 0);
        axios
          .get(globallink.url+'cmspage/'+15)
          .then(response => {
           // setmanageleads({manageleads_array:response.data.cms});
           setfa_content(response.data.cms.fa_content)
           seten_content(response.data.cms.en_content)
       

        });
        
      }, [])

      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("py-5 gx-0 membership right");
        }
        
      }, [])
    return (
        <>
        
            <div className={lang}>
                <div className="col-xl-11 col-11 mx-auto">
                    <div className="text-center">
                    <NavLink to={`${process.env.PUBLIC_URL}/renew_membership`} className="mainbgcolor border_radius_100 btn d-inline-block text-white px-4 mme">{t("Renew Membership")}</NavLink>
                    <NavLink to={`${process.env.PUBLIC_URL}/become_member`} className="goldenbgcolor border_radius_100 btn d-inline-block text-white px-4 mme1">{t("Become a Member")}</NavLink>
                    </div>
                    {i18nextLng == 'fa' ?
                <Markup content={fa_content} className="right"/>
                :
<Markup content={en_content} />
    }
                    {/* <p className="fontweightbold"> {t("Membership")}</p>
                    <p>{t("ACIC membership")}</p>
                    <p className="fontweightbold">{t("Membership Conditions")}</p>
                    <p>{t("All members")}</p>
                    <p>{t("In the case")}</p>
                    <p>{t("The Board")}</p>
                    <p>{t("member note")} </p>
                    <p className="fontweightbold">{t("Membership Classes")}</p>
                    <p>{t("two member")}</p>
                    <p className="fontweightbold">{t("class a")}</p>
                    <p> {t("class a p")}</p>
                    <p className="fontweightbold">{t("class b")}</p>
                    <p>{t("class b p")}</p>
                    <p className="fontweightbold">{t("Membership Fee")}</p>
                    <p>{t("membership fee p")}</p>
                    <p> {t("large family membership")}</p>
                    <p>{t("family note")}</p>
                    <p>{t("Single membership fee: $300")}</p>
                    <p className="fontweightbold">{t("Membership Benefits")}</p>
                    <p>{t("member benefit p")}</p>
                    <ul className="my-4 list-unstyled">
                        <li>{t("benefit one")}</li>
                        <li>{t("benefit two")}</li>
                        <li>{t("benefit three")}</li>
                        <li>{t("benefit four")}</li>
                    </ul>
                    <p>{t("benefit note p")}</p> */}
                    <div className="text-center mt-5">
                    <div className="text-center">
                        <NavLink to={`${process.env.PUBLIC_URL}/renew_membership`} className="mainbgcolor border_radius_100 btn d-inline-block text-white px-4 mme">{t("Renew Membership")}</NavLink>
                        <NavLink to={`${process.env.PUBLIC_URL}/become_member`} className="goldenbgcolor border_radius_100 btn d-inline-block text-white px-4 mme1">{t("Become a Member")}</NavLink>
                    </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default withTranslation("translations")(Membership);
