import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import {Table, Button} from "react-bootstrap";
// import context from 'react-bootstrap/esm/AccordionContext';
import { Markup } from 'interweave';
import { withTranslation, Trans } from "react-i18next";
import vector_logo_4 from "../../images/about-bg.png";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css'
import search from '../../images/search_icon.svg'
const Publication = ({ t, i18n }) => {
    
  const [publicat,setpublication]=useState({publications_array:[]})
    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };
    let i18nextLng = localStorage.getItem('i18nextLng');



    const month_form=(e)=>{
      //alert('form submit');
      e.preventDefault();
      axios.post(globallink.url+'public-search',{
         month:e.target.elements.month.value
      })
      .then( response => {
        setpublication({publications_array:response.data.cmss});
 
 
      })
      
     }
    const [currentPage, setCurrentPage] = useState(1);
    const pagePostsLimit = 14;
    useEffect(() => {

        axios
          .get(globallink.url+'publication')
          .then(response => {
            setpublication({publications_array:response.data.cmss});
          
       

        });
          
      }, []);
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      

 
    return (

        <>
            <div className="publication mb-5">
                <div className="row gx-0">
              
                    <div className="col-xl-5 d-none d-xl-block"><img className="img-fluid" src={vector_logo_4} alt="vector_logo_4" /></div>
                    <div className="col-xl-7 py-4">
                        <div className="align-items-center px-5 justify-content-between mb-3">
                          <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>
                <form onSubmit={month_form} className="pt-10">
                        <div className="row mb-4">
                        <div className="col-md-9">
                <input type="text" id="month" class="form-control" name="month"/>


<Button type="submit"  variant=""><img src={search} /></Button>
</div>
</div>
</form>
</div>
</div>

                        {publicat.publications_array.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit).map((val,index) => {
                         return (
                        <>
                        <p>
                          <a href={`https://afghancanada.com/backend/public/uploads/post/${val.featured_image}`} target="_blank">
                           
                          {i18nextLng == 'fa' ?
                                <>
                            {val.fa_title}
                            </>
                            :
                            <>
                            {val.en_name}
                            </>
                         }
                         
                            </a></p>
                        </>
                        )
                      })}

<div className="col-xl-11 col-11 mx-auto">
                    <div class="row">
                  <Pagination
        currentPage={currentPage}
        itemsPerPage={pagePostsLimit}
        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
        totalItems={publicat.publications_array.length}
        pageNeighbours={2}
      />
                    </div>
                    </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTranslation("translations")(Publication);
