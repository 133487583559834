import React,{useState,useEffect} from 'react';
import { NavLink } from 'react-bootstrap';
import { Table } from "react-bootstrap";

// 
import PresidentLoop from "../pages/PresidentLoop";
import ViocePresidentLoop from "../pages/ViocePresidentLoop";
import TreasurerLoop from "../pages/TreasurerLoop";
import SecretaryLoop from "../pages/SecretaryLoop";
import CulturalLoop from "../pages/CulturalLoop";
import WomenServicesLoop from "../pages/WomenServicesLoop";
import EducationalLoop from "../pages/EducationalLoop";
import YouthLoop from "../pages/YouthLoop";
import SocialLoop from "../pages/SocialLoop";
import InternalLoop from "../pages/InternalLoop";
import FinanceLoop from "../pages/FinanceLoop";
import { withTranslation, Trans } from "react-i18next";
import globallink from "../Globalurl";
import axios from 'axios';
// images import
import avatar_01 from "../../images/avatar_01.svg";
import avatar_02 from "../../images/avatar_02.svg";
import { Markup } from 'interweave';
import { Alert } from 'bootstrap';
const BoardDirectors = ({ t, i18n }) => {
    
    const [lang,set_lang] = useState("py-5 membership");
    let i18nextLng = localStorage.getItem('i18nextLng');
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("py-5 membership right");
        }
        
      }, [])
    // President
    const PresidentLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // ViocePresident
    const ViocePresidentLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Treasurer
    const TreasurerLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Secretary
    const SecretaryLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Cultural
    const CulturalLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // WomenServicesLoop
    const WomenServicesLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Educational
    const EducationalLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // YouthLoop
    const YouthLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Social
    const SocialLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Internal
    const InternalLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // 
    const FinanceLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]

    const[allmember,setmember]=useState({member_array:[]})
    const[allbourdmem,setbourdmember]=useState('')
    useEffect(() => {

        axios
          .get(globallink.url+'all-board-members')
          .then(response => {
            setmember({member_array:response.data.members});
           
       

        });
        axios
        .get(globallink.url+'allbourd')
        .then(response => {
            setbourdmember(response.data.bourdmember);
        })
          
      }, []);

    return (
        <>

            
            <div className={lang}>
                <div className="col-xl-11 col-10 mx-auto">
                    <div className="row">
{allmember.member_array.map((val,index) => {
    if(val.id == 1){
        return(
            <>
            <div className="py-5">
                <div className="col-xl-11 col-10 mx-auto">
                    <h4 className="fontsize18 fontweightbold">{t("Meet the board of directors")}</h4>
                    <p>{t("member desc")}</p>
                    <h4 className="fontsize18 fontweightbold">{t("Members")}:</h4>
                </div>
            </div>
            {/*  */}
            <div className="py-5 light_greybgcolor" style={{marginBottom: "30px"}}>
                <div className="col-xl-11 col-10 mx-auto">
                    <div className="d-inline-block mb-4 w-260px h-260px border_radius_15 overflow-hidden"><img className="img-fluid" src={globallink.urla+'uploads/post/'+val.featured_image} alt="avatar_01" /></div>
                    {i18nextLng == 'fa' ?
                    <h4 className="fontsize18 fontweightbold">{val.fa_title}</h4>
                    
                    :
<h4 className="fontsize18 fontweightbold">{val.en_name}</h4>
        }

{i18nextLng == 'fa' ?
                    <p><Markup content={val.fa_description} /></p>
        :
                    
                    <p><Markup content={val.en_description} /></p>
        
    }
                </div>
            </div>
            </>
        )

    }else{
        return(
            <>
            <div className="col-xl-6 px-4 col-lg-12 mb-4 mb-xl-0">
                            <div className="d-inline-block mb-4 w-260px h-260px border_radius_15 overflow-hidden"><img className="img-fluid" src={globallink.urla+'uploads/post/'+val.featured_image} alt="avatar_02" /></div>
                            {i18nextLng == 'fa' ?
                            <h4 className="fontsize18 fontweightbold">{val.en_title}</h4>
                            :
<h4 className="fontsize18 fontweightbold">{val.en_name}</h4>
        }

{i18nextLng == 'fa' ?
                            <p><Markup content={val.fa_description} /></p>
:
<p><Markup content={val.en_description} /></p>
    }

                        </div>
            </>
        )
        
    }
    
    
})}
                        
                    </div>
                </div>
            </div>
            
            
            
            
            <div className="pb-5">
                <div className="col-xl-11 col-10 mx-auto">
                    <h4 className="fontsize18 fontweightbold">{t("Former Board of Directors")}</h4>
                    {i18nextLng == 'fa' ?
                    <Markup content={allbourdmem.fa_description}/>
                    :

<Markup content={allbourdmem.description}/>
}
                    {/* <div className="row gx-0 mt-4">
                        <div className="col-xl-3 col-lg-6 p-3 paragraph_grey1bgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("President")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {PresidentLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-lg-6 p-3 light_greybgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Vice President")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {ViocePresidentLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                       
                        <div className="col-xl-3 col-lg-6 p-3 paragraph_grey1bgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Treasurer")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {TreasurerLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-lg-6 p-3 light_greybgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Treasurer")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {SecretaryLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-lg-6 p-3 light_greybgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Cultural Services")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {CulturalLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-lg-6 p-3 paragraph_grey1bgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Women Services")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {WomenServicesLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                       
                        <div className="col-xl-3 col-lg-6 p-3 light_greybgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Educational Services")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {EducationalLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        
                        <div className="col-xl-3 col-lg-6 p-3 paragraph_grey1bgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Youth Services")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {YouthLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                     
                        <div className="col-xl-4 col-lg-6 p-3 paragraph_grey1bgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Social Services")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {SocialLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        
                        <div className="col-xl-4 col-lg-6 p-3 light_greybgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Internal Communications")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {InternalLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                       
                        <div className="col-xl-4 col-lg-6 p-3 paragraph_grey1bgcolor">
                            <h5 className="fontsize16 mb-4 fontweightbold">{t("Finance &Accounts")}</h5>
                            <div className="table-responsive">
                                <Table className="table-borderless fontsize14">
                                    <tbody>
                                        {FinanceLoopArray.map((val) => {
                                            return (
                                                <tr>
                <td>{val.tdtext1}</td>
                {i18nextLng == 'fa' ?
                <td>{val.farsi}</td>
                :
                <td>{val.tdtext2}</td>
                    }
            </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default withTranslation("translations")(BoardDirectors);
