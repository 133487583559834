import React,{useState,useEffect} from 'react';
import { Form, InputGroup, FormControl, FormLabel, Button } from "react-bootstrap";
import { NavLink,useHistory } from "react-router-dom";
//
import vector_bg from "../../images/vector_logo_1.svg";
import user from "../../images/user.svg";
import passim from "../../images/pass.svg";
import facebook_circles from "../../images/facebook_circles.svg";
import twitter_cicles from "../../images/twitter_cicles.svg";
import google from "../../images/google.svg";
import axios from 'axios';
import globallink from "../Globalurl";
import { Markup } from 'interweave';
import DOMPurify from 'dompurify';
import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const Login = ({ t, i18n }) => {
    const history = useHistory()
    const [loginaray,setloginaray]=useState('');
    const [username,setusername]=useState('');
    const [pass,setpass]=useState('');
    let i18nextLng = localStorage.getItem('i18nextLng');
    const [lang,set_lang] = useState("card-body py-5 login_form px-5 h-100");
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("card-body py-5 login_form px-5 h-100 right");
        }
        
      }, [])
    

    
      const submitform = (e) => {
        

        axios
        .post(globallink.url+'login',{
            uname : username,
            upass : pass
        })
        .then( response => {
            //setloginaray(response.login.ate);
            localStorage.setItem('data',JSON.stringify(response.data.data));
            
            let obj = JSON.parse(localStorage.getItem('data'));

            
            //var companyid = obj.company_id;
            //alert(obj.access_token);
            
            toast.success('You are successfully logged in');

            
             setTimeout(() => { 
                 history.push(`${process.env.PUBLIC_URL}/membership-detail`);
                 //history.push(`${process.env.PUBLIC_URL}/`);
                 history.go(0)

             }, 300)
            //window.location.reload(`${process.env.PUBLIC_URL}/membership-detail`);
            //window.location.href=`${process.env.PUBLIC_URL}/membership-detail`
        })
        .catch(error => {
            toast.error(error.response.data.error);
        });


        e.preventDefault();
      }
    return (
        <>
        
        <div className="col-xl-11 col-11 mx-auto my-5">
                <div className="border_radius_100 overflow-hidden bg-white shadow_card">
                    <div className="d-flex row gx-0 align-items-center">
                    
                        <div className="col-xl-6"> 
                        
                            <div className={lang}>
                                <h4 className="maintextcolor fontsize32 mb-5 fontweightbold text-center">{t("Login")}</h4>
                                
                                <ToastContainer />
                                <Form id="main-login" onSubmit={submitform}>
                                    <FormLabel className="d-block mb-4">{t("Username")}</FormLabel>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text id="basic-addon1"><img className="img-fluid" src={user} alt="user" /></InputGroup.Text>
                                        <FormControl type="text" name="uname" required value={username} onChange={(e) => setusername(e.target.value)} placeholder={t("Type your username")} aria-label="Username" />
                                    </InputGroup>
                                    <FormLabel className="d-block mb-4">{t("Password")}</FormLabel>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text id="basic-addon2"><img className="img-fluid" src={passim} alt="user" /></InputGroup.Text>
                                        <FormControl type="password" name="upass" required value={pass} onChange={(e) => setpass(e.target.value)}  placeholder={t("Type your Password")} aria-label="Password" />
                                    </InputGroup>
                                    <Button type="submit" variant="" className="mainbgcolor border_radius_100 mb-4 text-white text-uppercase fontsize22 w-75 mx-auto mt-5 h-60px d-block">{t("Login")}</Button>
                        
                                </Form>
                            </div> 

                        </div>
                        <div className="col-xl-6">
                            <div className="card-body bg_vector p-4"><img className="img-fluid" src={vector_bg} alt="vectorbg" /></div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTranslation("translations")(Login);
