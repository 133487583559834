
import React,{useState,useEffect} from 'react';
import { Button, FormLabel, Dropdown } from "react-bootstrap";


const Duajawshan = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="py-5 video">
            <div className="iframesite">
                <div className="col-xl-11 col-11 mx-auto">
                    <iframe class="_3Xz9Z" title="Embedded Content" scrolling="no" name="htmlComp-iframe" allow="fullscreen" data-src="" src="https://www-afghancanada-com.filesusr.com/html/95f8d8_9cf3dedaebfb6c0fbf4a71159a389586.html" width="100%" height="103800px"></iframe>
            </div>
           </div>
           
                
        </div>
    );
}

export default Duajawshan;