import React,{useState,useEffect} from 'react';
import { Form } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

const Register = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="py-5">
                <div className="col-xl-11 col-10 mx-auto">

                


            <iframe width='100%' height='1300px' frameborder='no' src='https://acic.wildapricot.org/widget/Donate'  onload='tryToEnableWACookies("https://acic.wildapricot.org");' >
</iframe>
<script  type="text/javascript" language="javascript" src="https://acic.wildapricot.org/Common/EnableCookies.js" >
</script>

                   
                  
                
                </div>
            </div>
        </>
    )
}

export default Register;
