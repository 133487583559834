import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import {Table, Button} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css'
import search from '../../images/search_icon.svg'
const Dua = ({ t, i18n }) => {
    let i18nextLng = localStorage.getItem('i18nextLng');
    const [lang,set_lang] = useState("");
    const [dua,setDua]=useState({dua_array:[]})

    const [currentPage, setCurrentPage] = useState(1);
    const pagePostsLimit = 5;


    const month_form=(e)=>{
      //alert('form submit');
      e.preventDefault();
      axios.post(globallink.url+'dua-search',{
        search:e.target.elements.search.value
      })
      .then( response => {
        setDua({dua_array:response.data.dua});
 
 
      })
      
     }

    useEffect(() => {
        window.scrollTo(0, 0)

        axios
          .get(globallink.url+'dua')
          .then(response => {
            setDua({dua_array:response.data.dua});
          
       

        });

        
      }, [])
      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("rightt");
        }
        
      }, [])
    return (
        <>
            <div className="dua">
            <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>
                <form onSubmit={month_form} className="pt-10">
                        <div className="row mb-4">
                        <div className="col-md-9">
                <input type="text" id="search" class="form-control" name="search"/>


<Button type="submit"  variant=""><img src={search} /></Button>
</div>
</div>
</form>
</div>
</div>
            {dua.dua_array.slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit).map((val,index) => {
                         return (
                           
                <div className="col-xl-11 col-11 mx-auto wa">
                    <div class="row">


                    <div class="col-md-6 col-sm-6 col-xs-12">
                     <h5>
                        
                        {i18nextLng == 'fa' ?
                                <>
                            {val.fa_title}
                            </>
                            :
                            <>
                            {val.en_title}
                            </>
                         }
                        </h5>
                     </div>
                     <div class="col-md-6 col-sm-6 col-xs-12 aud-right">
                     <audio src={`https://afghancanada.com/backend/public/uploads/post/${val.featured_image}`} event="" variant="" type="audio/ogg" controls preload="metadata"> </audio>
                     </div>


                     </div>

                     
                    </div>
                    )})}

<div className="col-xl-11 col-11 mx-auto">
                    <div class="row">
                  <Pagination
        currentPage={currentPage}
        itemsPerPage={pagePostsLimit}
        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
        totalItems={dua.dua_array.length}
        pageNeighbours={2}
      />
                    </div>
                    </div>
                
            </div>
        </>
    )
}

export default withTranslation("translations")(Dua);
