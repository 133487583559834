import React, { Fragment, useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import globallink from "../Globalurl";
import { NavLink,useHistory } from "react-router-dom";
import axios from 'axios';
//import Calendar from 'react-calendar';
import {Table, Button} from "react-bootstrap";
import search from '../../images/search_icon.svg'
import vector_logo_6 from "../../images/vector_logo_6.png";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import Eventss from '../pages/events';
import styl from 'react-big-calendar/lib/css/react-big-calendar.css';

import Modal from 'react-bootstrap/Modal';
import { Markup } from 'interweave';

const Calendarpage = ({ t, i18n }) => {
    //const [value, onChange] = useState(new Date());
  const evets_array=[];
   let i18nextLng = localStorage.getItem('i18nextLng');
    // const evets_array= [
    //       {
    //             start: new Date('2023-01-02 04:15:00'),
    //     end: new Date('2023-01-02 09:09:00'),
    //           eventClasses: 'optionalEvent',
    //           title: 'test event',
    //           description: 'This is a test description of an event',
    //       },
    //       
    //   ];
    



    const history = useHistory()

    const [myEvents, setEvents] = useState('');
 const[event, setEvent] = useState('');


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let obj = JSON.parse(localStorage.getItem('calender'));
    const current = new Date();
const[Cdate, setDate] = useState('');
// useEffect(()=>{
//   setDate(` ${current.getDate()+1}/${current.getMonth()+1}/${current.getFullYear()}`)
//   },[])
 


    const month_form=(e)=>{
      //alert('form submit');
      e.preventDefault();
      axios.post(globallink.url+'calender-search',{
         month:e.target.elements.month.value
      })
      .then( response => {
        response.data.events.map((val,index) => { 

          localStorage.setItem('calender',JSON.stringify(response.data.events));
          
          setDate(obj[0].to_date);
          {i18nextLng == 'fa' ?
          evets_array.push({ start: new Date(val.from_date), end: new Date(val.to_date),  eventClasses: 'optionalEvent', location: (val.location),  title: (val.fa_title),description:(val.fa_description),})
          :
          evets_array.push({ start: new Date(val.from_date), end: new Date(val.to_date), eventClasses: 'optionalEvent', location: (val.location), title: (val.en_title),description:(val.en_description),})
          }
        })
        setEvents(evets_array);
 
 
      })
      
     }

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.description),
    []
  )


    useEffect(() => {     
        axios.get(globallink.url+'all-events')
          .then(response => {  

            response.data.events.map((val,index) => { 
              // let from_date = moment(val.from_date);
              // let to_date = moment(val.to_date);
              
             
              {i18nextLng == 'fa' ?
                evets_array.push({ start: new Date(val.from_date), end: new Date(val.to_date),  eventClasses: 'optionalEvent', location: (val.location),  title: (val.fa_title),description:(val.fa_description),})
                :
                evets_array.push({ start: new Date(val.from_date), end: new Date(val.to_date), eventClasses: 'optionalEvent', location: (val.location), title: (val.en_title),description:(val.en_description),})
              }
            
            })
            //alert(JSON.stringify(evets_array));
             console.log(evets_array);
             setEvents(evets_array);
        });          
      }, []);
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const localizer = momentLocalizer(moment);
      
    //const NewDate = moment().format('YYYY M D');
    //let datefromdb = new Date(NewDate);
    //datefromdb.setDate(datefromdb.getDate() -0);
    //let date = new Date(datefromdb);
    

    const Calendare = ({ state, selectDate, selectEvent }) => {
        const eventStyleGetter = (event, start, end, isSelected) => {
          return { 
            style: { backgroundColor: event.color } 
          }
        } 

}

const renderModel = (event) => {
  setShow(true);
setEvent(event)
}

        return (
            
        <>
            <div className="py-5">
           
                <div className="col-xl-12 col-12 mx-auto">
                    <div className="row gx-0">
                        <div className="col-xl-4 d-none d-sm-block"><div className="h-100"><img className="w-100 img-fluid" src={vector_logo_6} alt="vector_logo_6" /></div></div>
                        <div className="col-xl-8">
                        {/* <iframe width='100%' height='900px' frameborder='no' src='https://acic.wildapricot.org/widget/Upcoming-events'  onload='tryToEnableWACookies("http://acic.wildapricot.org");' ></iframe>
                        
                        <script  type="text/javascript" language="javascript" src="https://acic.wildapricot.org/Common/EnableCookies.js" ></script> */}
                          
                        
                        
                          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{event.title}
          
          <p className='eventsmal'><small>{event.location}</small></p>
          
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
        
          <Markup content={event.description} /></Modal.Body>
       
      </Modal>

      <div className='row'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>
                <form onSubmit={month_form} className="pt-10">
                        <div className="row mb-4">
                        <div className="col-md-9">
                <input type="text" id="month" class="form-control" name="month"/>


<Button type="submit"  variant=""><img src={search} /></Button>
</div>
</div>
</form>
</div>
</div> 
                       <Calendar
                          views = {['month','week','agenda']}
      localizer={localizer}
      events={myEvents}
      
      date={Cdate}
      defaultDate={Cdate}
      defaultView={Views.month}
      startAccessor="start"
      endAccessor="end"
      onNavigate={date => {
        let changeDate = moment(date).format("YYYY-MM-DD");
setDate(changeDate)
       }}
       
      
      
      onSelectEvent={(event) => {

        renderModel(event)
      } }
          
          selectable
          
          
      
      
      
    /> 
                      


      


    </div>
        
    

                    </div>
                </div>
            </div>
        </>
    
    )
}

export default Calendarpage;
