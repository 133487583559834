// import React,{useState,useEffect} from 'react';
// import globallink from "../Globalurl";
// import axios from 'axios';
// const now = new Date();


const events = [

  {
    id: 2,
    title: 'test',
    start: new Date('2021-10-27'),
    end: new Date('2021-10-27'),
    
  },

  
]


export default events;