import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import context from 'react-bootstrap/esm/AccordionContext';
import { Markup } from 'interweave';
import { NavLink } from "react-router-dom";
import { Pagination } from "react-pagination-bar"
import 'react-pagination-bar/dist/index.css'
const Services = () => {
    let i18nextLng = localStorage.getItem('i18nextLng');
    const[homeday,setprayer]=useState({prayer_array:[]});
    const [currentPage, setCurrentPage] = useState(1);
  const pagePostsLimit = 5;
   


  

    useEffect(() => {

        axios
        .get(globallink.url+'latest-news')
        .then( response => {
            setprayer({prayer_array:response.data.allpost});

        })
      },[]);
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      
      let lange = localStorage.getItem("lang");
 
    return (
        <>
        <div className="App">
        <div className="services py-5">
            <div classname="row" style={{padding: "0px 60px"}}>
      {homeday.prayer_array
        .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
        .map((val,index) => 
        
        
        <div className="col-xl-12 col-12 mx-auto wa" style={{float: "left", padding:"30px 10px"}}>

{i18nextLng == 'fa' ?
<>
                <h5><NavLink to={`${process.env.PUBLIC_URL}/detail/`+val.slug}>{val.fa_title}</NavLink></h5>
                
                {/* <Markup content={val.fa_description}/> */}
                    </>
                    :
                    <>
                    <h5><NavLink to={`${process.env.PUBLIC_URL}/detail/`+val.slug}>{val.en_title}</NavLink></h5>
                
                {/* <Markup content={val.en_description}/> */}
                    </>
            }
                
                </div>
        
        )}
      <Pagination
        currentPage={currentPage}
        itemsPerPage={pagePostsLimit}
        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
        totalItems={homeday.prayer_array.length}
        pageNeighbours={2}
      />
    </div>
        
            
            
        </div>
        </div>
        </>
    )
}

export default Services;
