import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import { Table } from "react-bootstrap";
import context from 'react-bootstrap/esm/AccordionContext';
import { Nav, Form, Button } from 'react-bootstrap';
import { Markup } from 'interweave';
import aboutbg from "../../images/about-bg.png";
import { withTranslation, Trans } from "react-i18next";

import phone from "../../images/phone.svg";
import email from "../../images/email.svg";
import address from "../../images/address.svg";
const Committees = ({ t, i18n }) => {

    const submit_footer=(e)=>{ 
        
        // e.prevent_default();
        e.preventDefault();
        e.target.reset();

        const answer = window.confirm("Message Sent Successfully");
        
        axios.post(globallink.url+'contact',{
            
     email:e.target.elements.email.value,
     fname:e.target.elements.fname.value,
     subject:e.target.elements.subject.value,
     msg:e.target.elements.msg.value,

    });
   
    
    }
    const [content,setcontent]=useState('')
    const [phonee,setphone]=useState('')
    const [adreses,setadress]=useState('')

    useEffect(() => {

        axios
          .get(globallink.url+'live-stream')
          .then(response => {
           // setmanageleads({manageleads_array:response.data.cms});
           setcontent(response.data.livestream.email);
           setphone(response.data.livestream.phone);
           setadress(response.data.livestream.adress);

        });
          
      }, []);

    let i18nextLng = localStorage.getItem('i18nextLng');
    const [lang,set_lang] = useState("about paragraph_grey1bgcolor mb-5");
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("about paragraph_grey1bgcolor mb-5 right");
        }
        
      }, [])



      // President
    const PresidentLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // ViocePresident
    const ViocePresidentLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Treasurer
    const TreasurerLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Secretary
    const SecretaryLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Cultural
    const CulturalLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // WomenServicesLoop
    const WomenServicesLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Educational
    const EducationalLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // YouthLoop
    const YouthLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Social
    const SocialLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // Internal
    const InternalLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]
    // 
    const FinanceLoopArray = [
        {
            key: "0",
            tdtext1: "1991 - 1993",
            tdtext2: "M. Ibrahim Qasimi",
            farsi:"م.ابراهیم قاسمی",
        },
        {
            key: "1",
            tdtext1: "1994 - 1995",
            tdtext2: "Faqir Hussain Shari",
            farsi:"فقیر حسین شرعی",
        },
    ]

    const[allmember,setmember]=useState({member_array:[]})
    useEffect(() => {

        axios
          .get(globallink.url+'all-board-members')
          .then(response => {
            setmember({member_array:response.data.members});
           
       

        });
          
      }, []);
    return (
       
            <div id="about" className={lang}>
                
                    <div className='container'>
                    <div className="row pt10">
                <div className="col-xl-7 col-lg-12 mb-4 mb-xl-0 text-center">
                            
                            <div className="card-body bg-white border_radius_15 shadow-sm f-form">
                                <Form onSubmit={submit_footer}>
                                    <div className="row">
                                        <div className="col-xl-4 mb-3 mb-xl-0">
                                            <Form.Control type="email" className="" name="email" id=""  placeholder={t("E-mail")} ></Form.Control>
                                        </div>
                                        <div className="col-xl-4 mb-3 mb-xl-0">
                                            <Form.Control type="text" className="" name="fname" id="" placeholder={t("Name")}></Form.Control>
                                        </div>
                                        <div className="col-xl-4 mb-3 mb-xl-0">
                                            <Form.Control type="text" className="" name="subject" id="" placeholder={t("Subject")}></Form.Control>
                                        </div>
                                        <div className="col-xl-12 my-4">
                                            <Form.Control as="textarea" name="msg" placeholder={t("Message")} rows={5} />
                                        </div>
                                    </div>
                                    <Button type="submit" className="mainbgcolor border_radius_100 px-4 text-white fontsize14 d-flex justify-content-start" variant="">{t("Submit")}</Button>
                                </Form>
                            </div>
                        </div>
                        
                        <div className="col-xl-5 col-lg-12 mb-0">
                            
                            <p className="maintextcolor fontsize20 d-flex align-items-center"><img className="img-fluid me-3" src={phone} alt="phone" /> <Markup content={phonee} /></p>
                            <p className="maintextcolor fontsize20 d-flex align-items-center"><img className="img-fluid me-3" src={email} alt="phone" /> <Markup content={content} /></p>
                            <p className="maintextcolor fontsize20 d-flex align-items-center"><img className="img-fluid me-3" src={address} alt="phone" /> <Markup content={adreses} /></p>
                        </div>
                </div>
            </div>
            </div>
            
                
    )
}

export default withTranslation("translations")(Committees);
