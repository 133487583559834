import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import context from 'react-bootstrap/esm/AccordionContext';
import { Markup } from 'interweave';
import aboutbg from "../../images/about-bg.png";
import { withTranslation, Trans } from "react-i18next";
const Committees = ({ t, i18n }) => {
    const [fa_content,setfa_content]=useState('')
    const [en_content,seten_content]=useState('')
    let i18nextLng = localStorage.getItem('i18nextLng');
    const [lang,set_lang] = useState("about paragraph_grey1bgcolor mb-5");
    
    useEffect(() => {
        window.scrollTo(0, 0)
        axios
          .get(globallink.url+'cmspage/'+16)
          .then(response => {
           // setmanageleads({manageleads_array:response.data.cms});
           setfa_content(response.data.cms.fa_content)
           seten_content(response.data.cms.en_content)
       

        });
      }, [])
      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("about paragraph_grey1bgcolor mb-5 right");
        }
        
      }, [])
    return (
        <>
            <div id="about" className={lang}>
                <div className="row gx-0 ">
                    <div className="col-xl-5 d-none d-xl-block"><img className="img-fluid h-100" src={aboutbg} alt="icon" /></div>
                    <div className="col-xl-7 py-5">
                        <div className="px-xl-5 px-4">
                        <h6 className="">{t("About Us")}</h6>
                            {/* <div className="d-flex align-items-center mb-4">
                                <NavLink to="#" className="fontsize16 p-0 me-3 fontweightregular goldentextcolor">Our History</NavLink>
                                <NavLink to="/expansion" className="fontsize16 fontweightregular maintextcolor p-0 me-3">Expansion Project</NavLink>
                                <NavLink to="/board_directors" className="fontsize16 fontweightregular maintextcolor p-0 me-3">Board of Directors</NavLink>
                            </div> */}
                             {i18nextLng == 'fa' ?
                <Markup content={fa_content} className="right"/>
                :
<Markup content={en_content} />
    } 
                            {/* <h5 className="goldentextcolor mb-2 text-capitalize fontsize24">{t("Background")}</h5>
                            <p className="fontsize18 maintextcolor">{t("about one")} </p>
                            <p className="fontsize18 maintextcolor">{t("about two")}  </p>
                            <p className="fontsize18 maintextcolor">{t("about three")}</p>
                            <h6 className="goldentextcolor mb-2 text-capitalize fontsize24">{t("Vision")}</h6>
                            <p className="fontsize18 maintextcolor">{t("vision a")}</p>
                            <h6 className="goldentextcolor mb-2 text-capitalize fontsize24">{t("Mission Statement")}</h6>
                            <p className="fontsize18 maintextcolor">{t("Mission a")}</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withTranslation("translations")(Committees);
