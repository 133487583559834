import React from 'react';
import { Form } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

const Register = () => {
    return (
        <>
            <div className="py-5">
                <div className="col-xl-11 col-10 mx-auto">


                
                <iframe width='100%' height='1700px' frameborder='no' src='http://acic.wildapricot.org/widget/event-4068813'  onload='tryToEnableWACookies("https://acic.wildapricot.org");' ></iframe>
                <script  type="text/javascript" language="javascript" src="https://acic.wildapricot.org/Common/EnableCookies.js" ></script>
                   
                  
                
                </div>
            </div>
        </>
    )
}

export default Register;
