import React, { useState,useEffect } from 'react';
import { Col, Nav } from "react-bootstrap";
import { NavLink,useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// images import
import logo from "../../images/logo.svg";
import globallink from "../Globalurl";
import loading from '../../images/loading.gif';
const MembershipDetail = () => {
    // 
    const history = useHistory();
    
    

    const Logout = (e) => {
        e.preventDefault();
        localStorage.clear();
        
         setTimeout(() => { 
             history.push(`${process.env.PUBLIC_URL}/login`);
             window.location.reload();
         }, 3000)
         //window.location.reload();
        //window.location.reload(`${process.env.PUBLIC_URL}/login`);
        toast.error('You are logged out');

    }
    
    const[memberid,setid] = useState('')
    
    

   {/* Profile view api all state */}
   const[memid, setMemid] = useState('');
    const[email,setEmail] = useState('');
    const[fulname, setDisplayName] = useState('');
    const[fname, setFname] = useState('');
    const[lname , setLname] = useState('');
    const[phone, setPhone] = useState('');
    const[stret, setStreet] = useState('');
    const[city, setCity] = useState('');
    const[province, setProvince] = useState('');
    const[Postcode, setPostcode] = useState('');
    const[payment, setPayment] = useState('');
    const[children, setChildren] = useState('');
    const[memstatus, setStatus] = useState('');
    const[membersince, setMembersince] = useState('');
    const[renewdata, setRenewdate] = useState('');

    const[memberprice, setPrice] = useState('');
    const[RenewalPeriod, setRenewalPeriod] = useState('');
    const[Mname,setMname] = useState('');
    const[Autorec, setAutorec] = useState('');
    const[Secondtoken, setsecondtoken] = useState('');

    
    

    const[Membername,setMembername] = useState('');
    let obj = JSON.parse(localStorage.getItem('data'));
    let obj2 = JSON.parse(localStorage.getItem('data2'));
    
    
    useEffect(() => {
        window.scrollTo(0, 0);

        

        if(obj === null){
            setTimeout(() => { 
                history.push(`${process.env.PUBLIC_URL}/login`);
                window.location.reload();
            }, 3000)
           //window.location.reload(`${process.env.PUBLIC_URL}/login`);
           toast.error('Login Again Token Expire');

        }else{
axios
            .post(globallink.url+'secondtoken',{
                AccountId : obj.Permissions[0].AccountId
            })
            .then(response => {
                localStorage.setItem('data2',JSON.stringify(response.data.data2));
                let obj2 = JSON.parse(localStorage.getItem('data2'));
           //     //alert(obj2.access_token);
               
            })
            
            setTimeout(() => { 
                window.location.href=`${process.env.PUBLIC_URL}/membership-details`

            }, 3000)
           
        }
        
    }, []);
    
    return (
        <>
        <div className="waload"><img src={loading}/></div>
           
        </>
    )
}

export default MembershipDetail;
