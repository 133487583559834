import React,{useState,useEffect} from 'react';
import { Col, Form, Button } from "react-bootstrap";
import { NavLink,useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import globallink from "../Globalurl";
import 'react-toastify/dist/ReactToastify.css';


const MembershipLevel = () => {
    let obj = JSON.parse(localStorage.getItem('data'));
    const history = useHistory();
    const[allmember,setallmember]=useState({memberpkg_array:[]})
    const data = new FormData();    
    const[pkgid, setPkgid] = useState('');
    const[Token, setToken] = useState('aa');
    const[Accid, setAccid] = useState('asd');
    




    useEffect(() => {
        window.scrollTo(0, 0);
        
        
            axios
           .post(globallink.url+'allmemberspkg',{
               token : obj.access_token,
               AccountId : obj.Permissions[0].AccountId
   
           })
           .then(response =>{
            
            setallmember({memberpkg_array:response.data});
               


           })


        






    }, []);


const memberid_post=(e)=>{
    e.preventDefault();
        data.append('pkgid', pkgid);
        data.append('token', Token);
        data.append('accid', Accid);
        
        localStorage.setItem('pkgid', pkgid);
        axios.post(globallink.url+'pkgselect',{
            token : obj.access_token,
            AccountId : obj.Permissions[0].AccountId,
            pkgid : pkgid

        })
       
        
            history.push(`${process.env.PUBLIC_URL}/membershippkg-detail`);
            
        
         
}
    return (
        <>
            <div className='py-5'>
                <Col xxl={10} xl={10} lg={12} md={12} className='mx-auto col-12'>
                    <div className='text-center fontsize26 fontweightbold mb-4'>Change Membership Level First Login</div>
                    <Col xxl={8} xl={8} lg={11} md={11} className='mx-auto col-11'>
                    <div className="iframesite mt-5">
                        <iframe width='100%' height='1600px' frameborder='no' scrolling="no" src='https://acic.wildapricot.org/widget/Sys/login'  onload='tryToEnableWACookies("https://acic.wildapricot.org");' ></iframe>
                    </div>
                    <script  type="text/javascript" language="javascript" src="https://acic.wildapricot.org/Common/EnableCookies.js" ></script>
                        {/* <div className='d-md-flex align-items-center'>
                            <p className='m-0 fontsize14 maintextcolor'>Select membership level</p>
                            <p className='m-0 ms-auto fontsize14 maintextcolor'><span className='redtextcolor fontweightbold'>*</span> Mandatory Fields</p>
                        </div>
                        <hr className='mt-1' />
                        <div className='d-md-flex align-items-start my-3'>
                            <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Membership Level</strong></h5>
                            <div className='ms-md-3'>
                                <Form onSubmit={memberid_post}>
                                {allmember.memberpkg_array.map((val,index) => {
                                    return(
                                        

                                    


                                <div class="form-check mb-3">
                                    <input class="form-check-input" type="radio" name="pkgid" id="FamilyAnnually" value={val.Id} onChange={(e)=>setPkgid(e.target.value)}/>
                                    <input type="hidden" className="" name="token" id="" value={obj.access_token} onChange={(e)=>setToken(e.target.value)} />
                                    <input type="hidden" className="" name="Accid" id="" value={obj.Permissions[0].AccountId} onChange={(e)=>setAccid(e.target.value)} />
                                    <label class="form-check-label fontsize14" for="FamilyAnnually">
                                        <strong>{val.Name} - $ {val.MembershipFee}.00 (CAD)</strong>
                                        <p className='m-0 paragraph_greytextcolor'>Subscription period: {val.RenewalPeriod.YearPeriod} {val.RenewalPeriod.Kind} Automatic renewal ({val.AutomaticRecurringPayments})</p>
                                    </label>
                                </div>
                                    )


                                })}
                                <div className='d-inline-block text-end mt-4'><Button variant="" className='btn_login text-center' type='submit'>Submit</Button></div>
                               </Form>
                            </div>
                            
                        </div> */}
                    </Col>
                </Col>
            </div>
            {/*  */}
            
        </>
    )
}

export default MembershipLevel;
