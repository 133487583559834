import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import { NavLink } from "react-router-dom";
import context from 'react-bootstrap/esm/AccordionContext';
import { Markup } from 'interweave';
import { Pagination } from "react-pagination-bar"
import 'react-pagination-bar/dist/index.css'
const Services = () => {
    let i18nextLng = localStorage.getItem('i18nextLng');
    const[homeday,setprayer]=useState({prayer_array:[]});
    const [currentPage, setCurrentPage] = useState(1);
  const pagePostsLimit = 5;
    useEffect(() => {

        axios
        .get(globallink.url+'all-obituaries')
        .then( response => {
            setprayer({prayer_array:response.data.allobituaries});

        })
      },[]);
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

 
    return (
        <>
        
            <div className="services py-5">
            <div classname="row" style={{padding: "0px 60px"}}>
           
                                    
             {homeday.prayer_array
                .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                  .map((val,index) =>
                                        
                <div className="col-xl-12 col-12 mx-auto wa" style={{float: "left", padding:"30px 10px"}}>

{i18nextLng == 'fa' ?
<>
                <h5><NavLink to={`${process.env.PUBLIC_URL}/obituarie/`+val.cemetry+'_'+val.id}>{val.fa_title} <span>Cemetry:{val.cemetry}<br></br>Date of Burial:{val.date_of_burial} <br></br>Date of Death:{val.date_of_death} </span></NavLink></h5>
                
                <Markup content={val.fa_description} className="tes"/>
                    </>
                    :
                    <>
                    <h5><NavLink to={`${process.env.PUBLIC_URL}/obituarie/`+val.cemetry+'_'+val.id}>{val.en_name} <span>Cemetry:{val.cemetry}<br></br>Date of Burial:{val.date_of_burial} <br></br>Date of Death:{val.date_of_death} </span></NavLink></h5>
                
                <Markup content={val.en_description.substring(0, 240)} className="tes"/>
                    </>
            }
                
                </div>
                )}


<Pagination
        currentPage={currentPage}
        itemsPerPage={pagePostsLimit}
        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
        totalItems={homeday.prayer_array.length}
        pageNeighbours={2}
      />
            </div>
            </div>
             
        </>
    )
}

export default Services;
