import React, { useState,useEffect,Component } from 'react';

import { withTranslation, Trans } from "react-i18next";
import { NavLink,useHistory } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import OffcanvasHeader from 'react-bootstrap/OffcanvasHeader';
import OffcanvasTitle from 'react-bootstrap/OffcanvasTitle';
import OffcanvasBody from 'react-bootstrap/OffcanvasBody';
import { Button, Navbar, NavItem, Form, Nav, Dropdown, InputGroup } from "react-bootstrap";
import globallink from "../components/Globalurl";
// images import
import homeicon from "../images/home_icon.svg";
import search_icon from "../images/search_icon.svg";
import logo from "../images/logo.svg";
import bar from "../images/bar.svg";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const Header = ({ t, i18n }) => {
    const history = useHistory()
    const options = [
        {
            name: <img className="img-fluid" src={bar} alt="bar" />,
            scroll: true,
            backdrop: false,
        },
    ];

    const Logout = (e) => {
        e.preventDefault();
        localStorage.clear();
        
         setTimeout(() => { 
             history.push(`${process.env.PUBLIC_URL}/login`);
             window.location.reload();
         }, 3000)
         //window.location.reload();
        //window.location.reload(`${process.env.PUBLIC_URL}/login`);
        toast.error('You are logged out');

    }

    //let obj = JSON.parse(localStorage.getItem("data"));
    let obj = localStorage.getItem("data");

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en',includedLanguages: 'en,fa' }, 'google_translate_element')
       }
       
       useEffect(() => {
         var addScript = document.createElement('script');
         addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
         document.body.appendChild(addScript);
         window.googleTranslateElementInit = googleTranslateElementInit;
         
       }, []);
    
       
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const toggleShow = () => setShow((s) => !s);
    const [value, setValue] = useState();

    const refresh = ()=>{
        // re-renders the component
        //setValue({});
        setTimeout(() => {
            setValue({});
            
        }, 3000)
        
    }
    localStorage.setItem('lang',document.documentElement.lang);
    //alert(document.documentElement.lang);
    // if(document.documentElement.lang ==='en'){
    //     alert(document.documentElement.lang);
    // }


   

    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
      window.location.reload();
    };


    return (
        <>
            <div className="top_bar">
                <div className="col-xl-11 col-11 mx-auto">
                <ToastContainer />
                    <Form className="py-3">
                        <div className="d-lg-flex align-items-center">
                            <NavLink className="text-center home d-block mb-3 mb-lg-0" exact to={`${process.env.PUBLIC_URL}/`}><img className="img-fluid" src={homeicon} alt="home icon" /></NavLink>
                            <div className="ms-auto d-md-flex align-items-center">
                                {/* <InputGroup size="md" className="mb-0 me-3">
                                    <Form.Control className="" placeholder="Search" name="" type="text" aria-describedby="inputGroup-sizing-sm" />
                                    <InputGroup.Text id="inputGroup-sizing-sm"><img className="img-fluid" src={search_icon} alt="search icon" /></InputGroup.Text>
                                </InputGroup> */}
                                

                                {obj == null ? 
                                
                                 <NavLink to={`${process.env.PUBLIC_URL}/login`} className="me-3 btn btn_login" onClick={ refresh } >{t("Login")}</NavLink>
                                :
                                
                                <Dropdown autoClose="outside">
                                    <Dropdown.Toggle variant="" className='me-3 btn_login' id="dropdown-basic">{t("Manage Profile")}</Dropdown.Toggle>
                                    <Dropdown.Menu placement="start">
                                        <Dropdown.Item href={`${process.env.PUBLIC_URL}/membership-detail`} onClick={ refresh }>{t("Profile")}</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={Logout} >{t("Logout")}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                }
                                <NavLink to={`${process.env.PUBLIC_URL}/Donate`} className="me-3 btn btn_login" onClick={ refresh }>{t("Donate")}</NavLink>
                                
                               

        <Dropdown >
                                    <Dropdown.Toggle variant="" className='me-3 btn_login' id="dropdown-basic">{t("Language")}</Dropdown.Toggle>
                                    <Dropdown.Menu placement="start">
                                        <Dropdown.Item href="" onClick={() => changeLanguage("fa")} >{t("Farsi")}</Dropdown.Item>
                                        <Dropdown.Item onClick={() => changeLanguage("en")}>{t("English")}</Dropdown.Item>
                                       
                                    </Dropdown.Menu>
                                </Dropdown>
                                
                                
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            {/*  */}
            <div className="navbar_header sticky-top bg-white">
                <div className="col-xl-11 col-11 mx-auto">
                    <Navbar className="p-0 align-items-center d-none d-xl-flex">
                        <div className="me-auto justify-content-between d-flex align-items-center w-100">
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/membership`} className="nav-link">{t("Membership")}</NavLink></NavItem>
                            {/* <NavItem><NavLink to="/services" className="nav-link">Services</NavLink></NavItem> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">{t("Services")}</Dropdown.Toggle>
                                <div className="dropdown-menu">
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/school`}>{t("Educational Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/funeral_and_burial`}>{t("Funeral And Burial")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/cultural`}>{t("Cultural")} </NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/women_services`}>{t("Women Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/youth_services`}>{t("Youth Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/empowerment`}>{t("Social Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/library`}>{t("Library")}</NavLink>
                                </div>
                            </Dropdown>
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/calendar`} className="nav-link">{t("Calendar")}</NavLink></NavItem>
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/publication`} className="nav-link">{t("Publication")}</NavLink></NavItem>
                            
                        </div>
                        <NavLink to={`${process.env.PUBLIC_URL}/`} className="w-100 text-center py-2"><img width="180" className="img-fluid" src={logo} alt="logo" /></NavLink>
                        <div className="ms-auto justify-content-between d-flex align-items-center w-100">
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/livestream`} className="nav-link">{t("Livestream")}</NavLink></NavItem>
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/dua`} className="nav-link">{t("Dua")}</NavLink></NavItem>
                            <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">{t("About")}</Dropdown.Toggle> 
                            <Dropdown.Menu>
                                    {/* <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/#about`}>Our History</NavLink> */}
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/about`}>{t("Our History")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/expansion`}>{t("Expansion Project")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/board_directors`}>{t("Board of Directors")}</NavLink>
                                    
                                </Dropdown.Menu>
                            </Dropdown>
                            <NavItem><Nav.Link href={`${process.env.PUBLIC_URL}/contact`} className="nav-link">{t("Contact")}</Nav.Link></NavItem>
                        </div>
                    </Navbar>
                    
                    <Button variant="" onClick={toggleShow} className="bar_btn btn d-block d-xl-none"><span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span></Button>
            <Offcanvas show={show} onHide={handleClose} >
                <OffcanvasHeader className="bg-white" closeButton>
                    <OffcanvasTitle className="w-100 text-center"><NavLink to={`${process.env.PUBLIC_URL}/`} className="d-block" onClick={handleClose}><img width="150" className="img-fluid" src={logo} alt="logo" /></NavLink></OffcanvasTitle>
                </OffcanvasHeader>
                <OffcanvasBody className="px-0">
                    <Navbar variant="light">
                        <Nav className="w-100 d-block list-unstyled">
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/membership`} className="nav-link" onClick={handleClose}>{t("Membership")}</NavLink></NavItem>
                            {/* <NavItem><NavLink to="/services" className="nav-link">Services</NavLink></NavItem> */}
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">Services <i classname="fa fa-arrow"></i></Dropdown.Toggle>
                                <Dropdown.Menu>
                                <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/school`} onClick={handleClose}>{t("Educational Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/funeral_and_burial`} onClick={handleClose}>{t("Funeral And Burial")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/cultural`} onClick={handleClose}>{t("Cultural")} </NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/women_services`} onClick={handleClose}>{t("Women Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/youth_services`} onClick={handleClose}>{t("Youth Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/empowerment`} onClick={handleClose}>{t("Social Services")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/library`} onClick={handleClose}>{t("Library")}</NavLink>
                                </Dropdown.Menu>
                            </Dropdown>
                            
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/calendar`} className="nav-link" onClick={handleClose}>{t("Calendar")}</NavLink></NavItem>
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/publication`} className="nav-link" onClick={handleClose}>{t("Publication")}</NavLink></NavItem>
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/livestream`} className="nav-link" onClick={handleClose}>{t("Livestream")}</NavLink></NavItem>
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/dua`} className="nav-link" onClick={handleClose}>{t("Dua")}</NavLink></NavItem>
                            <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">About <i classname="fa fa-arrow"></i></Dropdown.Toggle> 
                            <Dropdown.Menu>
                                    {/* <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/#about`}>Our History</NavLink> */}
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/about`} onClick={handleClose}>{t("Our History")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/expansion`} onClick={handleClose}>{t("Expansion Project")}</NavLink>
                                    <NavLink className="nav-link" to={`${process.env.PUBLIC_URL}/board_directors`} onClick={handleClose}>{t("Board of Directors")}</NavLink>
                                    
                                </Dropdown.Menu>
                            </Dropdown>
                            <NavItem><NavLink to={`${process.env.PUBLIC_URL}/contact`} className="nav-link" onClick={handleClose}>{t("Contact")}</NavLink></NavItem>
                        </Nav>
                    </Navbar>
                </OffcanvasBody>
            </Offcanvas>
                </div>
            </div>
            {/*  */}
            
        </>
    )
}
// 

    

   

export default withTranslation("translations")(Header);
