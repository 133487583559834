import React, { useState,useEffect } from 'react';
import { Col, Nav } from "react-bootstrap";
import { NavLink,useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// images import
import logo from "../../images/logo.svg";
import globallink from "../Globalurl";

const MembershipDetail2 = () => {
    // 
    const history = useHistory();
    
    

    const Logout = (e) => {
        e.preventDefault();
        localStorage.clear();
        
         setTimeout(() => { 
             history.push(`${process.env.PUBLIC_URL}/login`);
             window.location.reload();
         }, 3000)
         //window.location.reload();
        //window.location.reload(`${process.env.PUBLIC_URL}/login`);
        toast.error('You are logged out');

    }
    
    const[memberid,setid] = useState('')
    
    

   {/* Profile view api all state */}
   const[memid, setMemid] = useState('');
    const[email,setEmail] = useState('');
    const[fulname, setDisplayName] = useState('');
    const[fname, setFname] = useState('');
    const[lname , setLname] = useState('');
    const[phone, setPhone] = useState('');
    const[stret, setStreet] = useState('');
    const[city, setCity] = useState('');
    const[province, setProvince] = useState('');
    const[Postcode, setPostcode] = useState('');
    const[payment, setPayment] = useState('');
    const[children, setChildren] = useState('');
    const[memstatus, setStatus] = useState('');
    const[membersince, setMembersince] = useState('');
    const[renewdata, setRenewdate] = useState('');

    const[memberprice, setPrice] = useState('');
    const[RenewalPeriod, setRenewalPeriod] = useState('');
    const[Mname,setMname] = useState('');
    const[Autorec, setAutorec] = useState('');
    const[Secondtoken, setsecondtoken] = useState('');

    
    

    const[Membername,setMembername] = useState('');
    let obj = JSON.parse(localStorage.getItem('data'));
    let obj2 = JSON.parse(localStorage.getItem('data2'));
    
    
    useEffect(() => {
        window.scrollTo(0, 0);

        

        if(obj === null){
            setTimeout(() => { 
                history.push(`${process.env.PUBLIC_URL}/login`);
                window.location.reload();
            }, 3000)
           //window.location.reload(`${process.env.PUBLIC_URL}/login`);
           toast.error('Login Again Token Expire');

        }else{
            
            
            axios
           .post(globallink.url+'profile',{
               token : obj.access_token,
               AccountId : obj.Permissions[0].AccountId
   
           })
           .then( response => {
            setid(response.data.Id)




            
            
            

            axios
           .post(globallink.url+'membershipLevel',{
               token : obj2.access_token,
               AccountId : obj.Permissions[0].AccountId,
               Memid : response.data.MembershipLevel.Id
   
           })
            .then(response => {
            setPrice(response.data.MembershipFee+'.00');
            setRenewalPeriod(response.data.RenewalPeriod.YearPeriod);
            setMname(response.data.Name);
            setAutorec(response.data.AutomaticRecurringPayments);


        })





            axios
           .post(globallink.url+'viewpkg',{
               token : obj2.access_token,
               AccountId : obj.Permissions[0].AccountId,
               Memid : response.data.Id
   
           })
          
           .then( response => {
            if(response.data.reason === 'invalid_token'){

                history.push(`${process.env.PUBLIC_URL}/login`);
                    window.location.reload();
               
               toast.error('Login Again Token Expire');
               
            }
            
               setEmail(response.data.Email);
               setDisplayName(response.data.FieldValues[23].Value);
               setFname(response.data.FirstName);
               setLname(response.data.LastName);
               setMemid(response.data.Id);
               setPhone(response.data.FieldValues[29].Value);
               setStreet(response.data.FieldValues[30].Value);
               setCity(response.data.FieldValues[31].Value);
               setProvince(response.data.FieldValues[32].Value);
               setPostcode(response.data.FieldValues[33].Value);
               setPayment(response.data.FieldValues[54].Value['Label']);
               setChildren(response.data.FieldValues[55].Value);
               setStatus(response.data.FieldValues[52].Value['Value']);
               setMembersince(response.data.FieldValues[45].Value);
               setRenewdate(response.data.FieldValues[49].Value);

               
               

           })

            
           })
           
        }
        
    }, []);
    
    return (
        <>
        
            <div className='py-5'>
                <Col xxl={10} xl={10} lg={12} md={12} className='mx-auto col-12'>
                <div className="row">
                <div className="col-md-6">
                    <NavLink to={`${process.env.PUBLIC_URL}/profile`} className='mb-3 d-block goldentextcolor fontsize14 text-center fontweightbold text-decoration-underline'>Edit Profile</NavLink> 
                    </div>
                    <div className="col-md-6">
                    <a href="#" className='mb-3 d-block goldentextcolor fontsize14 text-center fontweightbold text-decoration-underline' onClick={Logout} >Logout</a>
                    </div>
</div>
                    <Col xxl={8} xl={8} lg={11} md={11} className='mx-auto col-11'>
                        <div className='paragraph_grey1bgcolor p-3 border_radius_10'>
                            <div className='border_radius_10 col-xxl-4 mb-3 col-9 text-center p-3 mx-auto bodybgcolor'>
                                <NavLink to="" className='mb-3 d-block'><img width="200" className='img-fluid' src={logo} alt='icon' /></NavLink>
                                <small className='m-0 d-block paragraph_greytextcolor'>Member ID: <strong>{memberid}</strong></small>
                                {/* <small className='m-0 d-block paragraph_greytextcolor'>Valid Until: <strong>Sunday, January 01, 2023</strong></small> */}
                            </div>
                            {/* <NavLink to="" className='mb-3 d-block goldentextcolor fontsize14 text-center fontweightbold text-decoration-underline'>Image Optimized for smartphones printable PDF</NavLink> */}
                            <div className='px-2 py-3'>
                                <div className='d-md-flex align-items-start mb-3'>
                                    <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Membership Level</strong></h5>
                                    <div className='ms-md-3'>
                                        <p className='fontsize14 m-0'>{Mname} - $ {memberprice} (CAD) <NavLink to={`${process.env.PUBLIC_URL}/membership-level`} className="text-decoration-underline goldentextcolor fontweightbold">Change</NavLink></p>
                                        <i className='d-block fontsize14 paragraph_greytextcolor'>Subscription period: <strong>{RenewalPeriod} Year</strong>. Automatic Recurring Payments {Autorec}</i>
                                    </div>
                                </div>
                                {/*  */}
                                <div className='d-md-flex align-items-start mb-3'>
                                    <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Membership Status</strong></h5>
                                    <div className='ms-md-3'>
                                        <p className='fontsize14 m-0'>{memstatus}</p>
                                    </div>
                                </div>
                                {/*  */}
                                <div className='d-md-flex align-items-start'>
                                    <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Membership Since</strong></h5>
                                    <div className='ms-md-3'>
                                        <p className='fontsize14 m-0'>{membersince}</p>
                                    </div>
                                </div>
                                {/*  */}
                                <div className='d-md-flex align-items-start mb-3'>
                                    <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Renewal due on</strong></h5>
                                    <div className='ms-md-3'>
                                        <p className='fontsize14 m-0'>{renewdata}</p>
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                            <div className='text-center mb-3'><p className='d-inline-block mainbgcolor text-white btn m-0'>Renew to {renewdata}</p></div>
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Membership</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{memid}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>First Name</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{fname}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Last Name</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{lname}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Spouse Full Name</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{fulname}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Email</strong></h5>
                                <div className='ms-md-3'>
                                    <Nav.Link href="mailto:{email}" className='goldentextcolor fontweightbold p-0 fontsize14 m-0'>{email}</Nav.Link>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Phone Number</strong></h5>
                                <div className='ms-md-3'>
                                    <Nav.Link href="tel:{phone}" className='goldentextcolor fontweightbold p-0 fontsize14 m-0'>{phone}</Nav.Link>
                                </div>
                            </div>
                            
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Street</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{stret}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>City</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{city}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Province</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{province}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Postal Code</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{Postcode}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Payment Method</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{payment}</p>
                                </div>
                            </div>
                            {/*  */}
                            <div className='d-md-flex align-items-start mb-3'>
                                <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Children Under the age of 18 full name</strong></h5>
                                <div className='ms-md-3'>
                                    <p className='fontsize14 m-0'>{children}</p>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Col>
            </div>
        </>
    )
}

export default MembershipDetail2;
