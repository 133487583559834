import React,{useState,useEffect} from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { NavLink } from 'react-router-dom';
import { Form, FormControl, FormGroup, Button, FormLabel } from "react-bootstrap";
import { withTranslation, Trans } from "react-i18next";
// 
import ShopTabDataLoop from "../pages/ShopTabDataLoop";

// imprt images
import vector_logo_5 from "../../images/vector_logo_5.svg";
import pay from "../../images/pay.svg";
import propduct01 from "../../images/shop_product.png";


const callback = function (key) { };
const RenewMembership = ({ t, i18n }) => {
    let i18nextLng = localStorage.getItem('i18nextLng');
    const [lang,set_lang] = useState("py-5 gx-0 membership");
    // ShopTabDataLoopArray
    const ShopTabDataLoopArray = [
        {
            key: "0",
            shop_product: propduct01,
        },
    ]
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("py-5 gx-0 membership right");
        }
        
      }, [])
    return (
        <>
        <div className={lang}>
                <div className="col-xl-11 col-11 mx-auto memb">
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="" key="1">
                            <div className="my-4">
                                <h3 className="fontweightbold mb-4">{t("Membership Renewal")}</h3>
                                <ul className="list-unstyled row mb-4">
                                    <li className="col-xl-4 col-lg-12 mb-3 mb-xl-0"><strong>{t("Start")}:</strong> {t("Thursday, November 26, 2020 12:00 AM")}</li>
                                    <li className="col-xl-4 col-lg-12 mb-3 mb-xl-0"><strong>{t("End")}:</strong> {t("Monday, December 31, 2029 11:30 PM")}</li>
                                    <li className="col-xl-4 col-lg-12 mb-3 mb-xl-0"><strong>{t("Location")}:</strong> {t("ACIC")}</li>
                                </ul>
                                <p className="fontweightbold">{t("Membership Terms and Conditions")}:</p>
                                <ul className="list-style">
                                    <div>
                                        <li className="fontsize16 fontweightbold">{t("Membership")}</li>
                                        <p> {t("renew member p")}</p>
                                    </div>
                                    <div>
                                        <li className="fontsize16 fontweightbold">{t("Membership Conditions")}</li>
                                        <p>{t("condition one")}</p>
                                        <p>{t("condition two")}</p>
                                        <p>{t("condition three")}</p>
                                        <p>{t("condition four")}</p>
                                        <p>{t("condition p")}</p>
                                    </div>
                                    <div>
                                        <li>{t("Membership Classes")}</li>
                                        <p>{t("two member")}:​</p>
                                    </div>
                                    <div>
                                        <li className="fontsize16 fontweightbold">{t("class a")}​:</li>
                                        <p>{t("class a p")}​​</p>
                                    </div>
                                    <div>
                                        <li className="fontsize16 fontweightbold">{t("class b")}:</li>
                                        <p>{t("class b p")}</p>
                                    </div>
                                    <div>
                                        <li className="fontsize16 fontweightbold">{t("Membership Fee")}:</li>
                                        <p>{t("membership fee p")}</p>
                                        <p>{t("large family membership")}</p>
                                    </div>
                                    <p>{t("family note")}</p>
                                    <p>{t("Single membership fee: $300")}</p>
                                    <div>
                                        <li className="fontsize16 fontweightbold">{t("Membership Benefits")}:</li>
                                        <p>{t("member benefit p")}</p>
                                        <p>{t("benefit one")}</p>
                                        <p>{t("benefit two")}</p>
                                        <p> {t("benefit four")}</p>
                                    </div>
                                    <p>{t("benefit note p")}</p>
                                </ul>
                                <p className="fontweightbold">{t("Registration")}</p>
                                <p className="fontweightbold m-0">{t("Renewal Form")} </p>
                                <p className="">{t("If you already")}</p>
                                
                            </div>
                        </TabPane>
                        
                    </Tabs>
                </div>
            </div>
            {/* <div className="py-5 gx-0 membership">
                <div className="col-xl-11 col-11 mx-auto">
                    <div className="iframesite">
                        <iframe width='100%' height='1700px' frameborder='no' scrolling="no" src='https://acic.wildapricot.org/widget/event-4068813'  onload='tryToEnableWACookies("https://acic.wildapricot.org");' ></iframe>
                    </div>
                    <script  type="text/javascript" language="javascript" src="https://acic.wildapricot.org/Common/EnableCookies.js" ></script>
                </div>
            </div> */}
        </>
    )
}

export default withTranslation("translations")(RenewMembership);
