let en = {
    translations: {
      "footer about": "Afghan Canadian Islamic Community (ACIC) is a non-profit charitable organization providing cultural, social and religious.",
      "Stay in Touch": "Stay in Touch",
      "E-mail": "E-mail",
      "Name":"Name",
      "Subject":"Subject",
      "Message":"Message",
      "Contact Info":"Contact Info",
      "Submit":"Submit",
      "copyright":"Copyright © 2021 - Afghan Canadian Islamic Community - All right reserved",
      "Privacy Policy":"Privacy Policy",
      "Terms & Conditions":"Terms & Conditions",
      "Login":"Login",
      "Manage Profile":"Manage Profile",
      "Profile":"Profile",
      "Logout":"Logout",
      "Donate":"Donate",
      "Language":"Language",
      "Farsi":"Farsi",
      "English":"English",
      "Membership":"Membership",
      "Services":"Services",
      "Educational Services":"Educational Services",
      "Funeral And Burial":"Funeral And Burial",
      "Cultural":"Cultural",
      "Women Services":"Women Services",
      "Youth Services":"Youth Services",
      "Social Services":"Social Services",
      "Library":"Library",
      "Calendar":"Calendar",
      "Publication":"Publication",
      "Livestream":"Livestream",
      "Dua":"Dua",
      "About":"About",
      "Our History":"Our History",
      "Expansion Project":"Expansion Project",
      "Board of Directors":"Board of Directors",
      "Contact":"Contact",
      "Prayer Time":"Prayer Time",
      "Imsak":"Imsak",
      "Maghrib":"Maghrib",
      "PM":"PM",
      "AM":"AM",
      "Sunset":"Sunset",
      "Zuhr":"Zuhr",
      "Sunrise":"Sunrise",
      "Fajr":"Fajr",
      "View Monthly Timings":"View Monthly Timings",
      "note":"Note These timings are a general guideline, please use precaution and check your local timings.",
      "View All Announcements":"View All Announcements",
      "Announcements":"Announcements",
      "Recent":"Recent",
      "Upcoming":"Upcoming",
      "Events":"Events",
      "View Calender":"View Calender",
      "Obituaries":"Obituaries",
      "Recent":"Recent",
      "View All Obituaries":"View All Obituaries",
      "Our Services":"Our Services",
      "committees":"committees",
      "About Us":"About Us",
      "Background":"Background",
      "about one":"Afghan Canadian Islamic Community (ACIC) is a non-proﬁt charitable organization providing cultural, social and religious services.",
      "about two":"ACIC began its activites as a small community association in 1989 in response to the growing need of Afghan immigrants for a place where they can practice their religion, traditions, and celebrate cultural ceremonies. Two years later, in 1991, the organization",
      "about three":"ACIC currently provides services to about 1200 families and individual members as well as hundreds of non-members who participate, enjoy our programs, and beneﬁt from our services.  ",
      "Vision":"Vision",
      "vision a":"A dynamic and inclusive community that constantly thrives towards cultural, social and spiritual growth",
      "Mission Statement":"Mission Statement",
      "Mission a":"To help members of the community make a positive difference by educating, inspiring and empowering them through a variety of educational and cultural programs.",
      "Family Membership Application":"Family Membership Application",
      "Individual Membership Application":"Individual Membership Application",
      "Pre-Authorized Debit form":"Pre-Authorized Debit form",
      "Pre-Authorized Credit form":"Pre-Authorized Credit form",
      "By-Law in English":"By-Law in English",
      "Dua Jawshan Kabeer":"Dua Jawshan Kabeer",
      "Dua-e-Kumayl":"Dua-e-Kumayl",
      "Meet the board of directors":"Meet the board of directors",
      "member desc":"ACIC’s affairs are primarily run and managed by a Board of Directors, along with the help of volunteers. Members of the Board of Directors are elected in annual general meeting by ACIC members and serve on a volunteer basis. Currently, the ACIC Board of Directors consists of the following eleven",
      "Members":"Members",
      "Former Board of Directors":"Former Board of Directors",
      "President":"President",
      "Vice President":"Vice President",
      "Treasurer":"Treasurer",
      "Cultural Services":"Cultural Services",
      "Internal Communications":"Internal Communications",
      "Finance &Accounts":"Finance &Accounts",
      "Renew Membership":"Renew Membership",
      "Become a Member":"Become a Member",
      "ACIC membership":"ACIC membership is available only to individuals interested in furthering the objectives of the organization, aged 18+, who have applied and have been accepted for membership in the Corporation by the Board of Directors.",
      "Membership Conditions":"Membership Conditions",
      "All members":"All members are required to obey the ACIC by-law, resolutions passed by the general meetings and written policies of the ACIC.",
      "In the case":"In the case of termination of membership, whether by resignation or expulsion, a member shall remain liable for payment of any arrears which become payable by him/her to the organization at the time of termination.",
      "The Board":"The Board has the authority to suspend or expel any member from the Corporation for any one or more of the following grounds: violating any provision of the articles, by-laws or written policies of the organization ; carrying out any conduct which may be detrimental to the Organization  as determined by the Board in its sole discretion; and for any other reason that the board in its sole and absolute discretion considers to be reasonable, having regard to the purpose of the organization.",
      "member not":"Note: Only the general meeting has the authority to restore the status of a suspended member if the suspended member appeals in a general meeting following a suspension by the Board of Directors:",
      "Membership Classes":"Membership Classes",
      "two member":"There are two classes of members in the ACIC:",
      "class a":"Class “A” Member",
      "class a p":"A Class “A” member is entitled to vote in the general meetings and can be nominated for a position on the Board of Directors after three years of membership",
      "class b":"Class “B” Member",
      "class b p":"A Class “B” member is non-voting member; however, he/she will automatically be converted to a Class “A” voting member following one full year of membership as a Class B non-voting member.",
      "Membership Fee":"Membership Fee",
      "membership fee p":"The membership fee is fixed by resolution of the Board and sanctioned by a majority vote at a general meeting of members. Currently, annual membership fees are as follows:",
      "large family membership.":"$600 for family membership.",
      "family note":"Note: A family membership fee covers parents and their children under 18. Children older than 18 or other relatives of the applicants such as father or mother, no matter if they live in the same house, should pay a separate membership fee.",
      "Single membership fee: $300":"Single membership fee: $300",
      "Membership Benefits":"Membership Benefits",
      "member benefit p":"All Class “A” Members whose membership has not been suspended are entitled to receive the following benefits as determined by resolution of the Board and sanctioned by a majority vote at a general meeting of members:",
      "benefit one":"Right to vote in the annual general meetings or any special general meeting.",
      "benefit two":"Right to be nominated for a position on the Board of Directors after three years from membership.",
      "benefit three":"A 50% discount on school fees (tuition fee only; books and other educational materials are excluded.)",
      "benefit four":"Free funeral and burial service for the principal member or any family member (family members include husband, wife and children under age 18)",
      "benefit note p":"Note: To be entitled to these benefits, all membership dues should have been paid on time.",
      "Membership Renewal":"Membership Renewal",
      "Start":"Start",
      "Thursday, November 26, 2020 12:00 AM":"Thursday, November 26, 2020 12:00 AM",
      "End":"End",
      "Location":"Location",
      "ACIC":"ACIC",
      "Monday, December 31, 2029 11:30 PM":"Monday, December 31, 2029 11:30 PM",
      "Membership Terms and Conditions":"Membership Terms and Conditions",
      "renew member p":"ACIC membership is available only to individuals interested in furthering the objectives of the organization , aged 18+, who have applied and have been accepted for membership in the Corporation by the Board of Directors.",
      "condition one":"All members are required to obey the ACIC by-law, resolutions passed by the general meetings and written policies of the ACIC",
      "condition two":"In the case of termination of membership, whether by resignation or expulsion, a member shall remain liable for payment of any arrears which become payable by him/her to the organization at the time of termination.",
      "condition three":"The Board has the authority to suspend or expel any member from the Corporation for any one or more of the following grounds:",
      "condition four":"violating any provision of the articles, by-laws or written policies of the organization ; carrying out any conduct which may be detrimental to the Organization  as determined by the Board in its sole discretion; and for any other reason that the board in its sole and absolute discretion considers to be reasonable, having regard to the purpose of the organization.",
      "condition p":"Note: Only the general meeting has the authority to restore the status of a suspended member if the suspended member appeals in a general meeting following a suspension by the Board of Directors:​",
      "Registration":"Registration",
      "Renewal Form":"Renewal Form",
      "member note":"member note",
      "If you already":"If you already have an account please log in to your account by click the Log in button on the top right of this page. If you forgot your password, you can reset your password at the log in page. ",
      "Username":"Username",
      "Type your username":"Type your username",
      "Password":"Password",
      "Type your Password":"Type your Password",
 
      
      





    }
  };
  
  export default en;
  