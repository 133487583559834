
import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import context from 'react-bootstrap/esm/AccordionContext';
import { Markup } from 'interweave';
import { Button, FormLabel, Dropdown } from "react-bootstrap";

import mic_icon from "../../images/mic_icon.svg";
import share_icon from "../../images/share_icon.svg";
import play_icon from "../../images/play_icon.svg";
import whatsapp_icon from "../../images/whatsapp_icon.svg";


const Livestream = () => {

    
       const [content,setcontent]=useState('https://www.youtube.com/embed/')
       const [phonee,setphone]=useState('')
       const [adreses,setadress]=useState('?autoplay=1')
    useEffect(() => {


        axios
          .get(globallink.url+'live-stream')
          .then(response => {
           // setmanageleads({manageleads_array:response.data.cms});
           
           setphone(response.data.livestream.live_streaming);

        });
        window.scrollTo(0, 0)
      }, [])
    return (
        
        <div className="py-5 video">
            <iframe width="100%" height="720" src={content+phonee+adreses} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
        </div>
    );
}

export default Livestream;