import React,{useState,useEffect} from 'react';
import { Col, Row, Button, Form } from "react-bootstrap";
import { NavLink,useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
 import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import globallink from "../components/Globalurl";
const Profile = () => {
    


    const history = useHistory()
    {/* Profile view api all state */}
   const[memid, setMemid] = useState('');
   const[email,setEmail] = useState('');
   const[fulname, setDisplayName] = useState('');
   const[fname, setFname] = useState('');
   const[lname , setLname] = useState('');
   const[phone, setPhone] = useState('');
   const[stret, setStreet] = useState('');
   const[city, setCity] = useState('');
   const[province, setProvince] = useState('');
   const[Postcode, setPostcode] = useState('');
   const[Token, setToken] = useState('');
   const[Accid, setAccid] = useState('');
   const[Password, setPassword] = useState('');
   const[memstatus, setStatus] = useState('');
   const[membersince, setMembersince] = useState('');
   const[renewdata, setRenewdate] = useState('');

    const[Membername,setMembername] = useState('');
    const[Memeberid, setMemberid] = useState('');
    const[children, setChildren] = useState('');
    let obj = JSON.parse(localStorage.getItem('data'));
    
    let obj2 = JSON.parse(localStorage.getItem('data2'));
    const data = new FormData();    
    // 
    useEffect(() => {
        window.scrollTo(0, 0);

        if(obj == null){
            setTimeout(() => { 
                history.push(`${process.env.PUBLIC_URL}/login`);
                window.location.reload();
            }, 3000)
           //window.location.reload(`${process.env.PUBLIC_URL}/login`);
           toast.error('Login Again Token Expire');

        }else{
            axios
           .post(globallink.url+'profile',{
               token : obj.access_token,
               AccountId : obj.Permissions[0].AccountId
   
           })
           .then( response => {
            setAccid(obj.Permissions[0].AccountId);
            setMemberid(response.data.MembershipLevel.Id);
            axios
           .post(globallink.url+'viewpkg',{
               token : obj2.access_token,
               AccountId : obj.Permissions[0].AccountId,
               Memid : response.data.Id
   
           })
           .then( response => {
            if(response.data.reason === 'invalid_token'){

                history.push(`${process.env.PUBLIC_URL}/login`);
                    window.location.reload();
               
               toast.error('Login Again Token Expire');
               
            }
               setEmail(response.data.Email);
               setDisplayName(response.data.FieldValues[23].Value);
               setFname(response.data.FirstName);
               setLname(response.data.LastName);
               setMemid(response.data.Id);
               setPhone(response.data.FieldValues[29].Value);
               setStreet(response.data.FieldValues[30].Value);
               setCity(response.data.FieldValues[31].Value);
               setProvince(response.data.FieldValues[32].Value);
               setPostcode(response.data.FieldValues[33].Value);
               setToken(obj2.access_token);
               
               setStatus(response.data.FieldValues[52].Value['Value']);
               setMembersince(response.data.FieldValues[45].Value);
               setRenewdate(response.data.FieldValues[49].Value);
               

               setChildren(response.data.FieldValues[55].Value);
               
                   
               

           })
           


           

            
           })
           
           
        }
        
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    const update_profile=(e)=>{
        e.preventDefault();
        data.append('FirstName', fname);
        data.append('LastName', lname);
        data.append('SpouseFullName', fulname);
        data.append('Email', email);
        data.append('Phone', phone);
        data.append('Street', stret);
        data.append('City', city);
        data.append('Province', province);
        data.append('PostalCode', Postcode);
        data.append('token', Token);
        data.append('accid', Accid);
        data.append('children',children);
        data.append('memid', memid);
        data.append('password',Password);

        // axios.post(globallink.url+'profupdate',data)
        // .then(function(response){
          

        // })
        // .catch(function (error){
        //   toast.error(error.response.data.errors);
        // });
        //var url = "https://api.wildapricot.org/v2.2/accounts/208762/contacts/32849899";
          var url = `https://api.wildapricot.org/v2.2/accounts/${Accid}/contacts/${memid}`;

var xhr = new XMLHttpRequest();
xhr.open("PUT", url);

xhr.setRequestHeader("Authorization", "Bearer "+Token);
xhr.setRequestHeader("accept", "application/json");
xhr.setRequestHeader("Content-Type", "application/json");

xhr.onreadystatechange = function () {
   if (xhr.readyState === 4) {
      console.log(xhr.status);
      console.log(xhr.responseText);
   }};

var datas = `{
  "FirstName": "`+fname+`",
  "LastName": "`+lname+`",
  "Email": "`+email+`",
  "MembershipLevel": {
    "Id": `+Memeberid+`
  },
  "MembershipEnabled": true,
  "Status": "Active",
  "Password": "`+Password+`",
  "FieldValues": [
    {
      "FieldName": "Phone Number",
      "SystemCode": "Phone",
      "Value": "`+phone+`"
    },
    {
        "FieldName": "Street",
        "SystemCode": "custom-11653339",
        "Value": "`+stret+`"
      },
      {
        "FieldName": "City",
        "SystemCode": "custom-11653340",
        "Value": "`+city+`"
      },
      {
        "FieldName": "Province",
        "SystemCode": "custom-11653341",
        "Value": "`+province+`"
      },
      {
        "FieldName": "Postal Code",
        "SystemCode": "custom-11653343",
        "Value": "`+Postcode+`"
      },
      {
        
        "FieldName": "Children Under the age of 18 Full Name",
        "SystemCode": "custom-11653847",
        "Value": "`+children+`"
      }
  ],
  "RecreateInvoice": true,
  "Id": `+memid+`
}`;
//alert(datas);
xhr.send(datas);


  setTimeout(() => { 
      history.push(`${process.env.PUBLIC_URL}/membership-detail`);
      //window.location.reload();
  },2000)
  toast.success('Profile Update');




    }
    
    return (
        <>
            <div className='py-5'>
                <Col xxl={10} xl={10} lg={12} md={12} className='mx-auto col-12'>
                    <div className='text-center fontsize26 fontweightbold mb-4'>My Profile</div>
                    <Col xxl={8} xl={8} lg={12} md={12} className='mx-auto col-12'>
                        <Form onSubmit={update_profile}>
                        <Form.Control type="hidden" className="" name="token" id="" value={obj2.access_token}></Form.Control>
                        <Form.Control type="hidden" className="" name="Accid" id="" value={obj.Permissions[0].AccountId}></Form.Control>
                        <Form.Control type="hidden" className="" name="memid" id="" value={memid}></Form.Control>
                            <Row className='profile_form paragraph_grey1bgcolor border_radius_10'>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" className="" name="fname" id="" onChange={(e)=>setFname(e.target.value)} value={fname}></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" className="" name="lname" id="" onChange={(e)=>setLname(e.target.value)} value={lname}></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>Spouse Full Name</Form.Label>
                                    <Form.Control type="text" className="" name="fulname" id="" onChange={(e)=>setDisplayName(e.target.value)} value={fulname}></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" className="" name="email" id="" onChange={(e)=>setEmail(e.target.value)} value={email}></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" className="" name="phone" id="" onChange={(e)=>setPhone(e.target.value)} value={phone} ></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>Street</Form.Label>
                                    <Form.Control type="text" className="" name="street" id="" onChange={(e)=>setStreet(e.target.value)} value={stret} ></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" className="" name="city" id="" onChange={(e)=>setCity(e.target.value)} value={city}></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4'>
                                    <Form.Label>Province</Form.Label>
                                    <Form.Control type="text" className="" name="province" onChange={(e)=>setProvince(e.target.value)} id="" value={province}></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4 mb-md-0'>
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control type="text" className="" name="postcode" id="" onChange={(e)=>setPostcode(e.target.value)} value={Postcode}></Form.Control>
                                </Col>
                                <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4 mb-md-0'>
                                    <Form.Label>Password *</Form.Label>
                                    <Form.Control type="password" className="" name="password" id="" onChange={(e)=>setPassword(e.target.value)} value={Password}></Form.Control>
                                </Col>
                                 <Col xxl={6} xl={6} lg={6} md={12} className='col-12 mb-4 mb-md-0'>
                                    <Form.Label>Children Under the age of 18 Full Name</Form.Label>
                                    <Form.Control type="text" className="" name="children" id="" onChange={(e)=>setChildren(e.target.value)} value={children}></Form.Control>
                                </Col> 
                                <div className='d-inline-block text-end mt-4'><Button variant="" className='btn_login text-center' type='submit'>Submit</Button></div>
                            </Row>
                        </Form>
                        
                    </Col>
                </Col>
            </div>
        </>
    )
}

export default Profile;
