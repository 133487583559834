import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import {Table, Button} from "react-bootstrap";


const MonthliTiming = () => {
    
    const month_form=(e)=>{
     //alert('form submit');
     e.preventDefault();
     axios.post(globallink.url+'month-time',{
        month:e.target.elements.month.value
     })
     .then( response => {
         setprayer({prayer_array:response.data.homeday});


     })
     
    }
    const[homeday,setprayer]=useState({prayer_array:[]})
    useEffect(() => {

        axios
        .get(globallink.url+'monthly-timing')
        .then( response => {
            setprayer({prayer_array:response.data.homeday});

        })
      },[]);
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="py-5">
                <div className="col-xl-11 col-10 mx-auto">
                    <form onSubmit={month_form}>
                        <div className="row mb-4">
                        <div className="col-md-6">
                <select id="month" name="month" class="form-control">
    <option>Select Month</option>
    <option value="1">January</option>
    <option value="2">February</option>
    <option value="3">March</option>
    <option value="4">April</option>
    <option value="5">May</option>
    <option value="6">June</option>
    <option value="7">July</option>
    <option value="8">August</option>
    <option value="9">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
</select>
</div>
<div className="col-md-6">
<Button type="submit" className="form-control mainbgcolor border_radius_100 px-4 text-white fontsize14 d-flex justify-content-center" variant="">Submit</Button>
</div>
</div>
</form>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Date</th>
                        <th>Imsak</th>
                        <th>Fajr</th>
                        <th>Sunrise</th>
                        <th>Zuhr</th>
                        <th>Asr</th>
                        <th>Sunset</th>
                        <th>Maghrib</th>
                        <th>Isha</th>
                        <th>Midnight</th>
                        </tr>
                    </thead>
                    <tbody>
                    {homeday.prayer_array.map((val,index) => {
                      return (
                          <>
                        <tr>
                        <td>{val.day}</td>
                        <td>{val.Imsak}</td>
                        <td>{val.Fajr}</td>
                        <td>{val.Sunrise}</td>
                        <td>{val.Dhuhr}</td>
                        <td>{val.Asr}</td>
                        <td>{val.Sunset}</td>
                        
                        <td>{val.Maghrib}</td>
                        <td>{val.Isha}</td>
                        <td>{val.Midnight}</td>
                        </tr>
                    </>
                    )
                })}
                    </tbody>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default MonthliTiming;
