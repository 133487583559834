import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import context from 'react-bootstrap/esm/AccordionContext';
import { Markup } from 'interweave';

const Detail = (props) => {
    let i18nextLng = localStorage.getItem('i18nextLng');
    const[recend,setprayer]=useState({recent_array:[]})
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    useEffect(() => {

        axios
          .get(globallink.url+'detail/'+props.match.params.slug)
          .then(response => {
           // setmanageleads({manageleads_array:response.data.cms});
           setprayer({recent_array:response.data.post});
       

        });
          
      }, []);
    return (
        <>
            <div className="services py-5">
                <div className="col-xl-11 col-11 mx-auto">
                    {recend.recent_array.map((val,index) => {
                                    
                                    return (
<>
{i18nextLng == 'fa' ?
<>
<h4 class="fontsize18 fontweightbold">{val.fa_title}</h4>
<p><Markup content={val.fa_description} /></p>
</>

:
<>
<h4 class="fontsize18 fontweightbold">{val.en_title}</h4>
<p><Markup content={val.en_description} /></p>
</>
                    }
                    <div className='wca'>
                    <img src={`https://www.afghancanada.com/backend/public/uploads/thumbnail/${val.featured_image}`} />
                </div>
</>
                                        )
                                    })}

                    
                </div>
            </div>
        </>
    )
}

export default Detail;
