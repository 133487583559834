import React,{useState,useEffect} from 'react';
import globallink from "../Globalurl";
import axios from 'axios';
import context from 'react-bootstrap/esm/AccordionContext';
import { Markup } from 'interweave';
import { withTranslation, Trans } from "react-i18next";
const Library = ({ t, i18n }) => {
    const [fa_content,setfa_content]=useState('')
    const [en_content,seten_content]=useState('')
    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };
    let i18nextLng = localStorage.getItem('i18nextLng');
   
    
    useEffect(() => {

        axios
          .get(globallink.url+'cmspage/'+8)
          .then(response => {
           // setmanageleads({manageleads_array:response.data.cms});
           setfa_content(response.data.cms.fa_content)
           seten_content(response.data.cms.en_content)
       

        });
          
      }, []);
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="services py-5">
                <div className="col-xl-11 col-11 mx-auto">
                   
                    {i18nextLng == 'fa' ?
                <Markup content={fa_content} className="right"/>
                :
<Markup content={en_content} />
    }
                    
                </div>
            </div>
        </>
    )
}

export default withTranslation("translations")(Library);
