let fa = {
    translations: {
      "footer about": "جامعه اسلامی افغانستان کانادایی یک سازمان خیریه غیرانتفاعی است که خدمات فرهنگی، اجتماعی و مذهبی را ارائه می دهد.",
      "Stay in Touch": "در تماس باش",
      "E-mail": "پست الکترونیک",
      "Name":"نام",
      "Subject":"موضوع",
      "Message":"پیام",
      "Contact Info":"اطلاعات تماس",
      "Submit":"ارسال",
      "copyright":"حق نشر © 2021 - جامعه اسلامی افغانستان کانادا - کلیه حقوق محفوظ است",
      "Privacy Policy":"سیاست حفظ حریم خصوصی",
      "Terms & Conditions":"شرایط و ضوابط",
      "Login":"وارد شدن",
      "Manage Profile":"مدیریت نمایه",
      "Profile":"مشخصات",
      "Logout":"خروج",
      "Donate":"اهدا کنید",
      "Language":"زبان",
      "Farsi":"فارسی",
      "English":"انگلیسی",
      "Membership":"عضویت",
      "Services":"خدمات",
      "Educational Services":"خدمات آموزشی",
      "Funeral And Burial":"تشییع و تدفین",
      "Cultural":"فرهنگی",
      "Women Services":"خدمات بانوان",
      "Youth Services":"خدمات جوانان",
      "Social Services":"خدمات اجتماعی",
      "Library":"کتابخانه",
      "Calendar":"تقویم",
      "Publication":"انتشار",
      "Livestream":"پخش زنده",
      "Dua":"دعا",
      "About":"در باره",
      "Our History":"تاریخ ما",
      "Expansion Project":"پروژه توسعه",
      "Board of Directors":"هيئت مدیره",
      "Contact":"مخاطب",
      "Prayer Time":"وقت نماز",
      "Imsak":"ایمساک",
      "Maghrib":"مغرب",
      "PM":"شب",
      "AM":"صبح",
      "Sunset":"غروب آفتاب",
      "Zuhr":"ظهر",
      "Sunrise":"طلوع خورشید",
      "Fajr":"فجر",
      "View Monthly Timings":"مشاهده زمان بندی ماهانه",
      "note":"توجه داشته باشید این زمان‌بندی‌ها یک دستورالعمل کلی است، لطفاً احتیاط کنید و زمان‌بندی محلی خود را بررسی کنید.",
      "View All Announcements":"مشاهده همه اطلاعیه ها",
      "Announcements":"اطلاعیه ها",
      "Recent":"اخیر",
      "Upcoming":"آینده",
      "Events":"مناسبت ها",
      "View Calender":"مشاهده تقویم",
      "Obituaries":"درگذشت",
      "Recent":"اخیر",
      "View All Obituaries":"مشاهده تمام آگهی های ترحیم",
      "Our Services":"خدمات ما",
      "committees":"کمیته ها",
      "About Us":"درباره ما",
      "Background":"زمینه",
      "about one":"جامعه اسلامی افغانستان کانادایی یک سازمان خیریه غیرانتفاعی است که خدمات فرهنگی، اجتماعی و مذهبی ارائه می کند.",
      "about two":"جامعه اسلامی افغانستان کانادایی در سال 1989 در پاسخ به نیاز روزافزون مهاجران افغان به مکانی که بتوانند در آن مذهب، سنت ها و مراسم فرهنگی خود را جشن بگیرند، فعالیت خود را به عنوان یک انجمن کوچک اجتماعی آغاز کرد. دو سال بعد، در سال 1991، سازمان",
      "about three":"جامعه اسلامی افغانستان کانادا در حال حاضر به حدود 1200 خانواده و اعضای فردی و همچنین صدها غیرعضو که شرکت می کنند، از برنامه های ما لذت می برند و از خدمات ما بهره مند می شوند، خدمات ارائه می دهد.  ",
      "Vision":"چشم انداز",
      "vision a":"جامعه ای پویا و فراگیر که دائماً در جهت رشد فرهنگی، اجتماعی و معنوی پیشرفت می کند",
      "Mission Statement":"بیانیه ماموریت",
      "Mission a":"کمک به اعضای جامعه با آموزش، الهام بخشیدن و توانمندسازی آنها از طریق انواع برنامه های آموزشی و فرهنگی، تغییرات مثبتی ایجاد کنند.",
      "Family Membership Application":"درخواست عضویت خانواده",
      "Individual Membership Application":"درخواست عضویت فردی",
      "Pre-Authorized Debit form":"فرم بدهی از قبل مجاز",
      "Pre-Authorized Credit form":"فرم اعتبار از قبل مجاز",
      "By-Law in English":"آیین نامه به زبان انگلیسی",
      "Dua Jawshan Kabeer":"دعای جوان کبیر",
      "Dua-e-Kumayl":"دعای کمیل",
      "Meet the board of directors":"با هیئت مدیره ملاقات کنید",
      "member desc":"امور جامعه اسلامی افغانستان کانادا عمدتاً توسط هیئت مدیره و با کمک داوطلبان اداره و اداره می شود. اعضای هیئت مدیره در جلسه عمومی سالانه توسط اعضای جامعه اسلامی افغانستان کانادا انتخاب می شوند و به صورت داوطلبانه خدمت می کنند. در حال حاضر، هیئت مدیره جامعه اسلامی افغانستان کانادا متشکل از یازده نفر زیر است",
      "Members":"اعضا",
      "Former Board of Directors":"هیئت مدیره سابق",
      "President":"رئيس جمهور",
      "Vice President":"معاون رئيس جمهور",
      "Treasurer":"خزانه دار",
      "Cultural Services":"خدمات فرهنگی",
      "Internal Communications":"ارتباطات داخلی",
      "Finance &Accounts":"امور مالی و حسابداری",
      "Renew Membership":"تمدید عضویت",
      "Become a Member":"عضو شوید",
      "ACIC membership":"عضویت جامعه اسلامی افغانستان کانادا فقط برای افرادی که علاقه مند به پیشبرد اهداف سازمان هستند، بالای 18 سال، که درخواست کرده اند و توسط هیئت مدیره برای عضویت در شرکت پذیرفته شده اند، در دسترس است.",
      "Membership Conditions":"شرایط عضویت",
      "All members":"همه اعضا موظف به اطاعت از آیین نامه جامعه اسلامی افغانستان کانادا، قطعنامه های تصویب شده توسط مجامع عمومی و سیاست های مکتوب جامعه اسلامی افغانستان کانادا هستند.",
      "In the case":"در صورت خاتمه عضویت، اعم از استعفا یا اخراج، یک عضو مسئول پرداخت معوقاتی است که در زمان خاتمه از سوی او به سازمان قابل پرداخت می‌شود.",
      "The Board":"هیئت مدیره این اختیار را دارد که هر یک از اعضای شرکت را به دلیل یک یا چند مورد زیر تعلیق یا اخراج کند: نقض هر یک از مفاد مواد، آیین‌نامه‌ها یا سیاست‌های مکتوب سازمان. انجام هر گونه رفتاری که ممکن است به تشخیص هیئت به تشخیص خود برای سازمان مضر باشد. و به هر دلیل دیگری که هیئت مدیره به صلاحدید و مطلق خود با توجه به هدف سازمان معقول بداند. ",
      "member not":"تبصره : در صورتی که عضو تعلیق شده در مجمع عمومی متعاقب تعلیق هیئت مدیره درخواست تجدید نظر نماید فقط مجمع عمومی صلاحیت اعاده وضعیت عضو معلق را دارد.",
      "Membership Classes":"کلاس های عضویت",
      "two member":"در جامعه اسلامی افغانستان کانادا دو دسته از اعضا وجود دارد:",
      "class a":"عضو کلاس یک",
      "class a p":"یک عضو کلاس الف حق رای در مجامع عمومی را دارد و پس از سه سال عضویت می تواند برای سمتی در هیئت مدیره نامزد شود. ",
      "class b":"عضو کلاس دو",
      "class b p":"یک عضو کلاس دو عضوی است که حق رای ندارد. با این حال، پس از یک سال عضویت کامل به عنوان عضوی بدون حق رای در کلاس دو، به طور خودکار به عضوی با حق رای کلاس یک تبدیل می شود.",
      "Membership Fee":"حق عضویت",
      "membership fee p":"حق عضویت با تصمیم هیئت مدیره تعیین و با اکثریت آرا در مجمع عمومی اعضا تصویب می شود. در حال حاضر حق عضویت سالانه به شرح زیر است:",
      "large family membership":"600 دلار برای عضویت خانواده.",
      "family note":"توجه: حق عضویت خانواده شامل والدین و فرزندان زیر 18 سال آنها می شود. فرزندان بالای 18 سال یا سایر بستگان متقاضی مانند پدر یا مادر، صرف نظر از اینکه در یک خانه زندگی می کنند، باید حق عضویت جداگانه پرداخت کنند.",
      "Single membership fee: $300":"حق عضویت تک نفره: 300 دلار",
      "Membership Benefits":"مزایای عضویت",
      "member benefit p":"کلیه اعضای طبقه الف که عضویت آنها به حالت تعلیق در نیامده است، حق دریافت مزایای زیر را دارند که با تصمیم هیئت مدیره تعیین شده و با اکثریت آرا در مجمع عمومی اعضا تصویب می شود:",
      "benefit one":"حق رای در مجامع عمومی سالانه یا هر مجمع عمومی فوق العاده.",
      "benefit two":"حق نامزدی برای سمت در هیئت مدیره پس از سه سال از عضویت.",
      "benefit three":"تخفیف 50 درصدی در شهریه مدرسه (فقط شهریه؛ کتاب ها و سایر مواد آموزشی استثنا نمی شوند.)",
      "benefit four":"تشییع جنازه و خاکسپاری رایگان برای عضو اصلی یا هر یک از اعضای خانواده (اعضای خانواده شامل زن، شوهر و فرزندان زیر 18 سال)",
      "benefit note p":"توجه: برای برخورداری از این مزایا، کلیه حق عضویت باید به موقع پرداخت شده باشد.",
      "Membership Renewal":"تمدید عضویت",
      "Start":"شروع کنید",
      "Thursday, November 26, 2020 12:00 AM":"پنجشنبه، 26 نوامبر 2020، ساعت 12:00 صبح",
      "End":"پایان",
      "Location":"محل",
      "ACIC":"ACIC",
      "Monday, December 31, 2029 11:30 PM":"دوشنبه 31 دسامبر 2029 ساعت 23:30",
      "Membership Terms and Conditions":"شرایط و ضوابط عضویت",
      "renew member p":"عضویت جامعه اسلامی افغانستان کانادا فقط برای افرادی که علاقه مند به پیشبرد اهداف سازمان هستند، بالای 18 سال، که درخواست کرده اند و توسط هیئت مدیره برای عضویت در شرکت پذیرفته شده اند، در دسترس است.",
      "condition one":"همه اعضا موظف به اطاعت از آئین نامه جامعه اسلامی افغانستان کانادا، قطعنامه های تصویب شده در مجامع عمومی و سیاست های مکتوب جامعه اسلامی افغانستان کانادا هستند.",
      "condition two":"در صورت خاتمه عضویت، اعم از استعفا یا اخراج، یک عضو مسئول پرداخت معوقاتی است که در زمان خاتمه از سوی او به سازمان قابل پرداخت می‌شود.",
      "condition three":"هیئت مدیره این اختیار را دارد که هر یک از اعضای شرکت را به هر یک یا چند مورد از دلایل زیر تعلیق یا اخراج کند:",
      "condition four":"نقض هر گونه مفاد مواد، آیین نامه ها یا سیاست های مکتوب سازمان؛ انجام هر گونه رفتاری که ممکن است به تشخیص هیئت به تشخیص خود برای سازمان مضر باشد. و به هر دلیل دیگری که هیئت مدیره به صلاحدید و مطلق خود با توجه به هدف سازمان معقول بداند.",
      "condition p":"تبصره: در صورتی که عضو تعلیق شده در مجمع عمومی متعاقب تعلیق هیئت مدیره درخواست تجدید نظر کند، فقط مجمع عمومی صلاحیت اعاده وضعیت عضو معلق را دارد:",
      "Registration":"ثبت",
      "Renewal Form":"فرم تمدید",
      "member note":"یادداشت عضو",
      "If you already":"اگر قبلاً یک حساب کاربری دارید، لطفاً با کلیک بر روی دکمه ورود به سیستم در سمت راست بالای این صفحه وارد حساب خود شوید. اگر رمز عبور خود را فراموش کرده اید، می توانید رمز عبور خود را در صفحه ورود به سیستم بازنشانی کنید.",
      "Username":"نام کاربری",
      "Type your username":"نام کاربری خود را تایپ کنید",
      "Password":"کلمه عبور",
      "Type your Password":"رمز عبور خود را تایپ کنید",
    }
  };
  
  export default fa;
  