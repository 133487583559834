import React, { useState,useEffect } from 'react';
import { Col, Nav } from "react-bootstrap";
import { NavLink,useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// images import
import logo from "../../images/logo.svg";
import globallink from "../Globalurl";

const MembershippkgDetail = () => {
    // 
    const history = useHistory()
    
    const[memberid,setid] = useState('')
    


   {/* Profile view api all state */}
   const[memid, setMemid] = useState('');
    const[MembershipFee,setMembershipFee] = useState('');
    const[fulname, setDisplayName] = useState('');
    const[Renewal, setRenewal] = useState('');
    const[lname , setLname] = useState('');
    const[phone, setPhone] = useState('');
    const[stret, setStreet] = useState('');
    const[city, setCity] = useState('');
    const[province, setProvince] = useState('');
    const[Postcode, setPostcode] = useState('');
    const[payment, setPayment] = useState('');
    const[children, setChildren] = useState('');
    const[memstatus, setStatus] = useState('');
    const[membersince, setMembersince] = useState('');
    const[renewdata, setRenewdate] = useState('');

    const[memberprice, setPrice] = useState('');
    
    const[Mname,setMname] = useState('');
    const[Autorec, setAutorec] = useState('');



    const[Membername,setMembername] = useState('');
    let obj = JSON.parse(localStorage.getItem('data'));
    let pk = JSON.parse(localStorage.getItem('pkgid'));
    useEffect(() => {
        window.scrollTo(0, 0);



        if(obj === null){
            setTimeout(() => { 
                history.push(`${process.env.PUBLIC_URL}/login`);
                window.location.reload();
            }, 3000)
           //window.location.reload(`${process.env.PUBLIC_URL}/login`);
           toast.error('Login Again Token Expire');

        }else{
            axios.post(globallink.url+'pkgselect',{
                token : obj.access_token,
                AccountId : obj.Permissions[0].AccountId,
                pkgid : pk
    
            })
            .then( response =>{
                setDisplayName(response.data.Name);
                setMembershipFee(response.data.MembershipFee);
                setRenewal(response.data.RenewalPeriod.Kind);
            })
           
           
        }
    }, []);
    return (
        <>
        
            <div className='py-5'>
                <Col xxl={10} xl={10} lg={12} md={12} className='mx-auto col-12'>
                
                
                    
                <div className='d-md-flex align-items-start my-3'>
                            <h5 className='m-0 fontsize16 maintextcolor mb-2 mb-lg-0'><strong>Membership Level</strong></h5>
                            <div className='ms-md-3'>
                                
                                        

                                    


                                <div class="form-check mb-3">
                                    
                                    <label class="form-check-label fontsize14" for="FamilyAnnually">
                                        <strong>{fulname} - $ {MembershipFee}.00 (CAD)</strong>
                                        <p className='m-0 paragraph_greytextcolor'>Subscription period: {Renewal} </p>
                                    </label>
                                </div>
                                 
                            </div>
                            {/*  */}
                        </div>
                   

                </Col>
            </div>
        </>
    )
}

export default MembershippkgDetail;
