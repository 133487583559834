import React,{useState,useEffect} from 'react';
import { withTranslation, Trans } from "react-i18next";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
// 
import vector_bg from "../../images/vector_logo_2.svg";
import moon1 from "../../images/moon1.svg";
import fajar_moon from "../../images/fajar_moon.svg";
import sunrize_moon from "../../images/sunrize_moon.svg";
import zuhar_moon from "../../images/zuhar_moon.svg";
import sunset_moon from "../../images/sunset_moon.svg";
import magrib_moon from "../../images/magrib_moon.svg";
import ser_icon1 from "../../images/sevices_icon1.svg";
import ser_icon2 from "../../images/sevices_icon2.svg";
import ser_icon3 from "../../images/sevices_icon3.svg";
import ser_icon4 from "../../images/sevices_icon4.svg";
import ser_icon5 from "../../images/sevices_icon5.svg";
import ser_icon6 from "../../images/sevices_icon6.svg";
import icon2 from "../../images/icon-2.png";
import bannervector from "../../images/banner-01.png";
import bannervector2 from "../../images/banner-02.png";
import speaker from "../../images/speakericon.svg";
import play from "../../images/playicon.svg";
import event from "../../images/eventicon.svg";
import obituaries from "../../images/obituariesicon.svg";
import aboutbg from "../../images/about-bg.png";

import globallink from "../Globalurl";
import axios from 'axios';
// 
import PrayerTimeData from "../PrayerTimeData";
import HomeServivesLoop from "../pages/HomeServicesLoop";
import whatsapp_icon from "../../images/logo-02.png";
import whatsapp_icon2 from "../../images/logo-03.png";
import whatsapp_icon3 from "../../images/logo-04.png";
let obj2 = JSON.parse(localStorage.getItem('data2'));
const Home = ({ t, i18n }) => {
    

        

    let i18nextLng = localStorage.getItem('i18nextLng');
    const [lang,set_lang] = useState("about paragraph_grey1bgcolor mb-5");
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      useEffect(() => {
        if(i18nextLng=='fa'){
            set_lang("about paragraph_grey1bgcolor mb-5 right");
        }
        
      }, [])
    var moment = require('moment-hijri');
    const PrayerTimeDataArray = [
        {
            key: "0",
            name: "Imsak",
            time: "4:53 AM",
            image: moon1,
        },
        {
            key: "1",
            name: "Fajr",
            time: "4:58 AM",
            image: fajar_moon,
        },
        {
            key: "2",
            name: "Sunrise",
            time: "6:32 AM",
            image: sunrize_moon,
        },
        {
            key: "3",
            name: "Zuhr",
            time: "1:20 PM",
            image: zuhar_moon,
        },
        {
            key: "4",
            name: "Sunset",
            time: "8:23 PM",
            image: sunset_moon,
        },
        {
            key: "5",
            name: "Maghrib",
            time: "8:23 PM",
            image: magrib_moon,
        },
    ]
    // HomeServicesLoopArray
    const HomeServicesLoopArray = [
        {
            key: "0",
            servicesicon1: ser_icon1,
            title: "Cultural Services",
            linksa: "cultural",
            farsi:"خدمات فرهنگی",
        },
        {
            key: "1",
            servicesicon1: ser_icon2,
            title: "Youth  Programs",
            linksa: "youth_services",
            farsi:"برنامه های جوانان",
        },
        {
            key: "2",
            servicesicon1: ser_icon3,
            title: "Social Services ",
            linksa: "empowerment",
            farsi:"خدمات اجتماعی",
        },
        {
            key: "3",
            servicesicon1: ser_icon4,
            title: "Maktab ",
            linksa: "school",
            farsi:"مکتب",
        },
        {
            key: "4",
            servicesicon1: ser_icon5,
            title: "Library ",
            linksa: "library",
            farsi:"کتابخانه",
        },
        {
            key: "6",
            servicesicon1: ser_icon6,
            title: "Funeral ",
            linksa: "funeral_and_burial",
            farsi:"مراسم خاکسپاری",
        },
    ]


    //const [manageleads,setmanageleads]=useState({manageleads_array:[]})
     const [title,settitle]=useState('')
     const [time,settime]=useState('')
     const [obituari,setobituaries]=useState({obituaries_array:[]})
     const [evente,setupcomingevent]=useState({upcoming_array:[]})
     const [recend,setrecend]=useState({recent_array:[]})
     const[homeday,setprayer]=useState({prayer_array:[]})
     //let i18nextLng = localStorage.getItem('i18nextLng');
     //console.log("local",i18nextLng)
    
    useEffect(() => {

        // axios
        //   .get(globallink.url+'cmspage/'+1)
        //   .then(response => {
        //    // setmanageleads({manageleads_array:response.data.cms});
        //     settitle(response.data.cms.title);
        // });
        
          
      }, []);
      useEffect(() => {

        axios
          .get(globallink.url+'prayer-home')
          .then(response => {
           // setmanageleads({manageleads_array:response.data.cms});
            settime(response.data.prayer_home);
        });
        
          
      }, []);

      useEffect(() => {

        axios
          .get(globallink.url+'all-obituaries/')
          .then(response => {
            setobituaries({obituaries_array:response.data.obituaries});
           //setobituaries(response.data.cms.title)
       

        });
          
      }, []);

      useEffect(() => {
        // axios
        // .post(globallink.url+'secondtoken',{
        //     AccountId : 208762
        // })
        // .then(response => {
        //     localStorage.setItem('data2',JSON.stringify(response.data.data2));
        //     let obj2 = JSON.parse(localStorage.getItem('data2'));
        //     //alert(obj2.access_token);
            
        // });
        // axios
        // .post(globallink.url+'wildevent',{
        //     token : obj2.access_token,
            
        // })
        // .then(response => {
        //     setupcomingevent({upcoming_array:response.data.Events});
            
        // });
          axios
          .get(globallink.url+'upcoming-events')

          .then(response => {
             setupcomingevent({upcoming_array:response.data.events});
          });


      },[]);

      useEffect(() => {

        axios
        .get(globallink.url+'latest-news')
        .then( response => {
            setrecend({recent_array:response.data.recent});

        })
      },[]);

      useEffect(() => {

        axios
        .get(globallink.url+'all-prayers')
        .then( response => {
            setprayer({prayer_array:response.data.homeday});

        })
      },[]);
     
      const changeLanguage = lng => {
        i18n.changeLanguage(lng);
      };
      
    return (
        <>
         
    
            <Carousel>
  <Carousel.Item>
  <NavLink to={`${process.env.PUBLIC_URL}/donate`} ><img
      className="d-block w-100"
      src={bannervector}
      alt="First slide"
    /></NavLink>
    
  </Carousel.Item>
  <Carousel.Item>
  <NavLink to={`${process.env.PUBLIC_URL}/donate`} ><img
      className="d-block w-100"
      src={bannervector2}
      alt="Second slide"
    />
    </NavLink>
    
  </Carousel.Item>  
</Carousel>


		
      
            <div className="col-xl-12 col-12 mx-auto">
                <div className="d-flex row gx-0">
                    <div className="col-xl-6">
                        <div className="card-body h-100 py-5 shadowbg overflow-hidden paragraph_grey2bgcolor px-5">
                            <div className="text-center">
                                <h4 className="fontsize28 fontweightbold maintextcolor text-uppercase">{t("Prayer Time")}</h4>
                                <p className="m-0 fontsize16 goldentextcolor">{time}</p>
                                 {/* <p className="m-0 fontsize16 goldentextcolor">16 Dhul Qadah 1442</p> */}
                                 <p className="m-0 fontsize16 goldentextcolor">
                                    {/* {moment({time}).format('iD/iM/iYYYY')}; */}
                                    {moment({time}).format('iMMMM iD iYYYY')};

                                    
                                    
                                    </p> 
                                <div className="table-responsive mt-4 mb-4 namaz-table">
                                    <Table>
                                        <tbody>
                                            {homeday.prayer_array.map((val,index) => {
                                                return (
                                                    <>
                                                    <tr className="highlight" key={index}>
                                                     <td>{t("Imsak")}</td>
                                                      <td className="text-center"><img className="img-fluid" src={moon1} alt="moon" /></td>
                                                      <td>{val.Imsak} </td>
                                                    </tr>
                                                    <tr className="highlight">
                                                     <td>{t("Fajr")}</td>
                                                      <td className="text-center"><img className="img-fluid" src={fajar_moon} alt="moon" /></td>
                                                      <td>{val.Fajr} </td>
                                                    </tr>
                                                    <tr className="highlight">
                                                     <td>{t("Sunrise")}</td>
                                                      <td className="text-center"><img className="img-fluid" src={sunrize_moon} alt="moon" /></td>
                                                      <td>{val.Sunrise} </td>
                                                    </tr>
                                                    <tr className="highlight">
                                                     <td>{t("Zuhr")}</td>
                                                      <td className="text-center"><img className="img-fluid" src={zuhar_moon} alt="moon" /></td>
                                                      <td>{val.Dhuhr} </td>
                                                    </tr>
                                                    <tr className="highlight">
                                                     <td>{t("Sunset")}</td>
                                                      <td className="text-center"><img className="img-fluid" src={sunset_moon} alt="moon" /></td>
                                                      <td>{val.Sunset} </td>
                                                    </tr>
                                                    <tr className="highlight">
                                                     <td>{t("Maghrib")}</td>
                                                      <td className="text-center"><img className="img-fluid" src={magrib_moon} alt="moon" /></td>
                                                      <td>{val.Maghrib} </td>
                                                    </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="home_audio">
    
    <audio src="https://afghancanada.com/azan.mp3" event="" variant="" type="audio/ogg" controls preload="metadata">
  
</audio>
  </div>
                                <p className="m-0 goldentextcolor">
                                
                                    {t("note")} </p>
                                <NavLink to={`${process.env.PUBLIC_URL}/monthly-timing`} className="d-inline-block maintextcolor fontweightmeduim px-4 py-2 mt-4 btn goldenbgcolor border_radius_100">{t("View Monthly Timings")}</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="text-start h-100 align-items-center ">
                            <div className="card-body mainbgcolor px-5 py-5 shadowbg overflow-hidden geh h-100">
                                <div className="golden_head mb-4"><img width="30" className="me-2 img-fluid" src={speaker} alt="icon" /><strong className="me-2">{t("Recent")}</strong> {t("Announcements")}</div>
                                <div className="ps-4">
                                {recend.recent_array.map((val,index) => {
                                        
                                        return (
                                            <>
                                            {i18nextLng == 'fa' ?
                                    <p className="paragraph" key={index}><img width="15" className="me-2 img-fluid" src={play} alt="icon" /><NavLink to={`${process.env.PUBLIC_URL}/detail/`+val.slug}> {val.fa_title}</NavLink></p>
:
                                    <p className="paragraph" key={index}><img width="15" className="me-2 img-fluid" src={play} alt="icon" /><NavLink to={`${process.env.PUBLIC_URL}/detail/`+val.slug}> {val.en_title}</NavLink></p>
                                    
                                        }
                                    </>
                                        )
                                })}
                                <NavLink className="morek" to={`${process.env.PUBLIC_URL}/AllAnnouncements`}>{t("View All Announcements")}</NavLink>
                                </div>
                                <div className="golden_head mb-4"><img width="30" className="me-2 img-fluid" src={event} alt="icon" /><strong className="me-2">{t("Upcoming")}</strong>  {t("Events")}</div>
                                <div className="ps-4">
                                    
                                {evente.upcoming_array.slice(0, 3).map((val,index) => {
                                        
                                        return (
                                            <>
                                            
                                    <p className="paragraph" key={index}><img width="15" className="me-2 img-fluid" src={play} alt="icon" /> 
                                    {i18nextLng == 'fa' ? 
                                    <>
{val.fa_title}
</>
:
<>
                                    {val.en_title}
                                    </>
                                          }  </p>
                                    
                                    
                                    </>
                                    )
                                })}
                                <NavLink className="morek" to={`${process.env.PUBLIC_URL}/calendar`}>{t("View Calender")}</NavLink>
                                </div>
                                <div className="golden_head mb-4"><img width="30" className="me-2 img-fluid" src={obituaries} alt="icon" /><strong className="me-2">{t("Obituaries")}</strong>  {t("Recent")}</div>
                                <div className="ps-4">
                                {obituari.obituaries_array.map((val,index) => {
                                    
                                        
                                        return (
                                            <>
                                            {i18nextLng == 'fa' ? 


                                    <p className="paragraph" key={index}><img width="15" className="me-2 img-fluid" src={play} alt="icon" /> <NavLink to={`${process.env.PUBLIC_URL}/obituarie/`+val.cemetry+'_'+val.id}> {val.fa_title} </NavLink></p>
                                    :
                                    <p className="paragraph" key={index}><img width="15" className="me-2 img-fluid" src={play} alt="icon" /> <NavLink to={`${process.env.PUBLIC_URL}/obituarie/`+val.cemetry+'_'+val.id}> {val.en_name}</NavLink></p>
                                            }
                                    {/* <p className="paragraph"><img width="15" className="me-2 img-fluid" src={play} alt="icon" /> {val.date_of_death}</p>
                                    <p className="paragraph"><img width="15" className="me-2 img-fluid" src={play} alt="icon" /> {val.date_of_burial}</p>
                                    <p className="paragraph"><img width="15" className="me-2 img-fluid" src={play} alt="icon" /> {val.cemetry}</p> */}
                                    </>
                                    )
                                })}
                                <NavLink className="morek" to={`${process.env.PUBLIC_URL}/AllObituaries`}>{t("View All Obituaries")}</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
          
            <div className="pt-100 pb-100 serviveshome">
                <div className="col-xl-11 col-11 mx-auto h-100">
                    <div className="text-center">
                        <h5 className="fontsize30 fontweightbold text-uppercase maintextcolor mb-5">{t("Our Services")}</h5>
                    </div>
                    <div className="row">
                        {HomeServicesLoopArray.map((val,index) => {
                            return (
                                <div className="col-xl-4 col-lg-6 mb-5 text-center">
                <div className="servivesbox">
                    <NavLink to={val.linksa}>
                    <p className="m-0"><img className="img-fluid" src={val.servicesicon1} alt="icon" /></p>
                    {i18nextLng == 'fa' ? 
                    <h5>{val.farsi}</h5>
                    :
                  <h5>{val.title}</h5>
                    }
                    </NavLink>
                </div>
            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="row">

            
            <div class="text-center"><h5 class="fontsize30 fontweightbold text-uppercase maintextcolor mb-5">{t("committees")}</h5></div>
            <div className="col-xl-7 col-7 mx-auto ">
                <div className="row whitebgcolor">
                    <div className="col-xl-4 col-lg-4 mb-5 text-center">
                     <div className="servivesbox">
                     <p className="m-0">
                     <NavLink to={`${process.env.PUBLIC_URL}/empowerment`}> <img className="img-fluid" src={whatsapp_icon} alt="icon" />
                     </NavLink>
                     </p>
                     </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 mb-5 text-center">
                     <div className="servivesbox">
                     <p className="m-0">
                     <a href='https://maktab.ca/' target="new">
                         <img className="img-fluid" src={whatsapp_icon2} alt="icon" />
                         </a>
                         </p>
                     </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 mb-5 text-center">
                     <div className="servivesbox">
                     <p className="m-0">
                     <NavLink to={`${process.env.PUBLIC_URL}/youth_services`}>
                         <img className="img-fluid" src={whatsapp_icon3} alt="icon" />
                         </NavLink>
                         </p>
                     </div>
                    </div>
                    </div>
                    </div>

                    
                    
                    </div>
            {/*  */}
            <div id="about" className={lang}>
                <div className="row gx-0 ">
                    <div className="col-xl-5 d-none d-xl-block"><img className="img-fluid h-100" src={aboutbg} alt="icon" /></div>
                    <div className="col-xl-7 py-5">
                        <div className="px-xl-5 px-4">
                            <h6 className="">{t("About Us")}</h6>
                            {/* <div className="d-flex align-items-center mb-4">
                                <NavLink to="#" className="fontsize16 p-0 me-3 fontweightregular goldentextcolor">Our History</NavLink>
                                <NavLink to="/expansion" className="fontsize16 fontweightregular maintextcolor p-0 me-3">Expansion Project</NavLink>
                                <NavLink to="/board_directors" className="fontsize16 fontweightregular maintextcolor p-0 me-3">Board of Directors</NavLink>
                            </div> */}
                            
                            <h5 className="goldentextcolor mb-2 text-capitalize fontsize24">{t("Background")}</h5>
                            <p className="fontsize18 maintextcolor">{t("about one")} </p>
                            <p className="fontsize18 maintextcolor">{t("about two")}  </p>
                            <p className="fontsize18 maintextcolor">{t("about three")}</p>
                            <h6 className="goldentextcolor mb-2 text-capitalize fontsize24">{t("Vision")}</h6>
                            <p className="fontsize18 maintextcolor">{t("vision a")}</p>
                            <h6 className="goldentextcolor mb-2 text-capitalize fontsize24">{t("Mission Statement")}</h6>
                            <p className="fontsize18 maintextcolor">{t("Mission a")}</p>
                        </div>
                    </div>
                </div>
            </div>



            

        </>
    )
}

export default withTranslation("translations")(Home);
