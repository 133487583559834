
import React,{useState,useEffect} from 'react';
import { Button, FormLabel, Dropdown } from "react-bootstrap";


const Duakumayl = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className="py-5 video">
            <div className="col-xl-11 col-11 mx-auto">
            <wix-iframe data-src=""><div classname="_1WP0F"><iframe classname="_3Xz9Z" scrolling="no" title="Embedded Content" name="htmlComp-iframe" allow="fullscreen" data-src="" src="https://www-afghancanada-com.filesusr.com/html/95f8d8_c7cdbc3ca2459bfba652c61a7f9e5b01.html" width="100%" height="22000px"></iframe></div></wix-iframe>
                
            </div>
        </div>
    );
}

export default Duakumayl;